import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Checkbox, Popconfirm, message, Button, Spin } from 'antd';
import dayjs from 'dayjs';
import { PAY_PURPOSES, PAY_TYPES } from 'common_constants/business';
import { request } from '../../../../tools';
import { ROLES } from 'common_constants/business';
import { setModal, setCurrentClient } from '../../../../store/commonReducer';
import { WALLET_ASSIGNMENT, HOTLINE_SUBMIT, CONFIRM_ADD_CONTRACT, INFO_INSURANCE } from 'common_constants/modals';
import EditSugar from '../../../../Modals/AddSugar/EditSugar';
import { getContractByIndex } from '../../Helpers';

const SugarItem = ({ sugar, handleSetApproved }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const [loadApproved, setLoadApproved] = useState(false);

  const [editSugarModalVisible, setEditSugarModalVisible] = useState(false);
  const [selectedEditSugarObject, setSelectedEditSugarObject] = useState(null);

  const [loading, setLoading] = useState(false);

  const showEditSugarModal = () => {
    setSelectedEditSugarObject(sugar);
    setEditSugarModalVisible(true);
  };

  const hideEditSugarModal = (e) => {
    e.stopPropagation();
    setEditSugarModalVisible(false);
    setSelectedEditSugarObject(null);
  };

  const onChangeApproved = (e) => {
    e.stopPropagation();
    if (!inspector) {
      message.warning('У вас немає прав на зміну статусу перевірки.');
      return;
    }
    setLoadApproved(true);
    request
      .post(
        '/sugar/setApproved',
        { sugarId: sugar._id, status: !sugar?.approved?.status },
        (res) => {
          handleSetApproved(sugar._id, res.approved);
          message.success('Статус перевірки змінено.');
        },
        () => message.error('Не вдалось змінити статус перевірки.'),
      )
      .finally(() => setLoadApproved(false));
  };

  const handleGetContractByIndex = async () => {
    setLoading(true);

    await getContractByIndex({ index: sugar.contractIndex, dispatch, setCurrentClient, setModal, CONFIRM_ADD_CONTRACT });

    setLoading(false);
  };

  const handleOpenModalInfoClick = (e) => {
    e.stopPropagation();
    switch (sugar.payfor) {
      case '4':
        dispatch(
          setModal({
            name: WALLET_ASSIGNMENT,
            data: { contractIndex: sugar.contractIndex, assignmentId: sugar.assignment.assignmentId },
          }),
        );
        break;
      case '5':
        dispatch(
          setModal({
            name: HOTLINE_SUBMIT,
            data: { hotlineIdFromWallet: sugar.hotlineId },
          }),
        );
        break;
      case '8':
        dispatch(
          setModal({
            name: INFO_INSURANCE,
            data: { insuranceId: sugar.insuranceId },
          }),
        );
        break;
      default:
        handleGetContractByIndex();
        break;
    }
  };

  const payforConditions = {
    5: 'hotlineId',
    8: 'insuranceId',
    9: 'contractIndex',
  };

  const buttonOrText =
    sugar.payfor === '50' || (payforConditions[sugar.payfor] && !sugar[payforConditions[sugar.payfor]]) ? (
      <>
        {PAY_PURPOSES?.[sugar?.payfor]?.label} | {PAY_TYPES[sugar.payBy]?.label} {sugar.payByOther}
      </>
    ) : (
      <Button type="primary" style={{ marginTop: 15, height: 55, padding: '4px 5px' }} onClick={handleOpenModalInfoClick}>
        {PAY_PURPOSES?.[sugar?.payfor]?.label} <br />
        {PAY_TYPES[sugar.payBy]?.label} {sugar.payByOther}
      </Button>
    );

  const confirm = (
    <Popconfirm
      title={`Сахарок ${sugar.approved?.status ? '' : 'не'} перевірено`}
      description="Бажаєте змінити статус перевірки?"
      onConfirm={onChangeApproved}
      okText="Так"
      cancelText="Ні"
      onClick={(e) => e.stopPropagation()}
      onCancel={(e) => e.stopPropagation()}
    >
      <Checkbox checked={sugar?.approved?.status} disabled={loadApproved}>
        <Row style={{ fontSize: '12px' }}>{users[sugar.approved?.userId]?.p ?? '-'}</Row>
      </Checkbox>
    </Popconfirm>
  );

  const amount = Math.round(sugar.amount);

  return (
    <Spin spinning={loading}>
      <Card className="sugarInner sugarInner-sugar-item" id={sugar._id} onClick={showEditSugarModal}>
        <Row className="item" justify={'space-between'} align={'middle'}>
          <Col flex={'auto'}>
            <Row className="fields">
              <Row className="info">{dayjs(sugar.payday).format('DD.MM.YYYY')}</Row>

              <Row className="info desktop">
                {PAY_PURPOSES['52'].value === Number(sugar.payfor) || PAY_PURPOSES['53'].value === Number(sugar.payfor) ? '- ' : ''} {amount}
              </Row>
              <Row className="info desktop">{sugar.payroll_info?.[0]?.usersAmount?.map((i) => i.amount).join('+') || '-'}</Row>
              <Row className="info desktop">{sugar?.name ?? 'Невідомо'}</Row>
              <Row className="info desktop">{buttonOrText}</Row>
              <Row className="info desktop">{confirm}</Row>

              <Row className="info mobile">
                <Row>
                  <b>Сума:</b> &nbsp;
                  {amount}
                </Row>
                <Row>
                  <b>Відсоток:</b> &nbsp;
                  {sugar.payroll_info?.[0]?.usersAmount?.map((i) => i.amount).join('+') || '-'}
                </Row>
                <Row>
                  <b>Клієнт:</b> &nbsp; {sugar?.name ?? 'Невідомо'}
                </Row>
                <Row>
                  <b>Спосіб оплати:</b>
                </Row>
                <Row style={{ marginBottom: 10 }}>{buttonOrText}</Row>
                <Row>
                  <b>Перевірено:</b> &nbsp; {confirm}
                </Row>
              </Row>
            </Row>
          </Col>
        </Row>

        <EditSugar disabledForBalance={true} open={editSugarModalVisible} onCancel={hideEditSugarModal} data={selectedEditSugarObject} />
      </Card>
    </Spin>
  );
};

export default React.memo(SugarItem);
