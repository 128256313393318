import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DownOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SearchOutlined,
  SmallDashOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { Button, Card, DatePicker, Form, Input, InputNumber, Radio, Spin, Switch, Table, Typography } from 'antd';

import { ExcelBtn, Row } from 'common_components';
import { BILL_STATUSES, LAW_BLOCKS, PAY_PURPOSES, PAY_TYPES, ROLES } from 'common_constants/business/index';
import { ADD_HARVEST } from 'common_constants/modals';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

import Box from '../../components/Box';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import SearchSelectPayType from '../../components/SearchSelect/SearchSelectPayType';
import SearchSelectBlock from '../../components/SearchSelect/SearchSelectBlock';
import UserAvatar from '../../components/UserAvatar';
import { setHarvestList, setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request, viewNameUserAndVerify } from '../../tools';
import HarvestInfo from './HarvestInfo';

import './Harvest.scss';

const { Text } = Typography;
const { Item } = Form;

const Harvest = () => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.harvest);
  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const mob = useSelector((state) => state.ui.mob);
  const FILII = useSelector((state) => state.common.FILII);

  const [rabfilOnly, rabfil, managerOnly, deputyManagerOnly, blockManagerOnly, inspector, admin] = [
    ROLES[userAuth.role] === ROLES.rabfil,
    ROLES[userAuth.role] <= ROLES.rabfil,
    ROLES[userAuth.role] === ROLES.manager,
    ROLES[userAuth.role] === ROLES.deputyManager,
    ROLES[userAuth.role] === ROLES.blockManager,
    ROLES[userAuth.role] <= ROLES.inspector,
    ROLES[userAuth.role] === ROLES.admin,
  ];

  const tabs = [{ label: 'Мої', value: 'myOnly' }];

  if (managerOnly || deputyManagerOnly || inspector) tabs.push({ label: 'Філія', value: 'filOnly' });
  if (blockManagerOnly || inspector) tabs.push({ label: 'Блок', value: 'blockOnly' });
  if (inspector) tabs.push({ label: 'Всі', value: 'all' });
  if (rabfilOnly) tabs.push({ label: 'Мої договори', value: 'contractOnly' });

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [tab, setTab] = useState(tabs[0].value); // ['myOnly', 'filOnly', 'all']
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);
  const [filtersData, setFiltersData] = useState({});
  const [sortData, setSortData] = useState(0);
  const [harvestSort, setHarvestSort] = useState(0);
  const [contractSort, setContractSort] = useState(0);
  const [payDateSort, setPayDaySort] = useState(1);
  const [loading, setLoading] = useState(false);

  const columns = [
    ...(mob
      ? [
          {
            title: 'Інфо',
            key: 'mobX3',
            render: (harvest) => (
              <Row style={{ alignItems: 'center' }}>
                <div style={{ textAlign: 'center', marginRight: 8 }}>
                  <UserAvatar user={users?.[harvest.author]} />
                  <br />
                  <span style={{ textTransform: 'uppercase' }}>{users[harvest.author]?.p}</span>
                </div>
                <div>
                  {dayjs(harvest.payday).format('DD.MM.YYYY')} <br /> {FILII[harvest.contractFil || harvest.fil] || '-'}
                  <br />
                  {LAW_BLOCKS[harvest.contractBlock?.[0]] || '-'}
                </div>
              </Row>
            ),
          },
        ]
      : [
          {
            title: 'Дата платежу',
            dataIndex: 'payday',
            key: 'payday',
            render: (payday) => dayjs(payday).format('DD.MM.YYYY'),
          },
          {
            title: 'Хто вніс',
            dataIndex: 'author',
            key: 'author',
            render: (item) => viewNameUserAndVerify(item, users),
          },
          {
            title: 'Філія',
            dataIndex: 'contractFil',
            key: 'contractFil',
            render: (contractFil, harvest) => FILII[contractFil || harvest.fil],
          },
        ]),

    {
      title: 'Клієнт',
      dataIndex: 'name',
      key: 'name',
      render: (name) => name || '-',
    },
  ];

  const getAllHarvest = (data = filtersData) => {
    setLoading(true);

    if (data.contractIndex === null) data.contractIndex = undefined;
    if (data.partOfName === '') data.partOfName = undefined;
    if (data.paydayRange === null) data.paydayRange = undefined;

    setFiltersData(data);

    const body = { ...data, ...sortData, [tab]: true, page: currentPage, includeSugars: true };

    request.post('/harvest/get', body, ({ data, total }) => {
      setTotal(total);
      dispatch(setHarvestList(data));
      setLoading(false);
    });
  };

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/harvest/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleChangeTab = (e) => {
    setTab(e.target.value);
    setCurrentPage(1);
  };

  const handleSearch = (formData) => {
    setCurrentPage(1);
    getAllHarvest(formData);
  };

  useEffect(() => {
    getAllHarvest();
  }, [currentPage, tab]);

  const formatedExportData =
    exportData &&
    exportData?.length > 0 &&
    exportData?.map((item) => [
      item.i,
      item.createdAt && dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
      users[item?.author]?.p,
      FILII[item.fil],
      item.contractIndex,
      dayjs(item.payday).format('DD.MM.YYYY'),
      item.amount,
      item.name,
      PAY_PURPOSES?.[item?.payfor]?.label + ' ' + item.payforOther,
      item.name,
      PAY_TYPES[item.payBy]?.label + ' ' + item.payByOther,
      BILL_STATUSES[item.bill]?.label + ' ' + item.billOther,
      item.comment,
    ]);
  formatedExportData?.unshift([
    '№ інформації',
    'дата внесення',
    'хто вніс',
    'філія внесача',
    '№договора',
    'Дата платежа',
    'Сума платежа',
    'id клієнта',
    'призначення платежу',
    'Прізвище клієнта + (невідомо)',
    'Куди платив',
    'Наявність квитанції',
    'Примітка',
  ]);

  if (!users) return null;

  return (
    <div className="clients_page default_page harvest_page">
      <h2>Жнива</h2>
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_HARVEST }))}>
          <PlusCircleOutlined /> Звітувати
        </Button>
      </Row>
      <br />
      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <DatePicker.RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}

      {rabfil && (
        <>
          <div className="row align filters" onClick={() => setFiltersOpen(!filtersOpen)}>
            {filtersOpen ? <DownOutlined /> : <RightOutlined />} <h2>Пошук</h2>
          </div>
          {filtersOpen && (
            <Card className="filtersContainer">
              <Form onFinish={handleSearch} initialValues={{ fil: inspector ? '' : userAuth.fil }}>
                <div className="inputs-wrapper">
                  <Item label="Філія" name="fil">
                    <SearchSelectFil disabled={ROLES[userAuth.role] > ROLES.inspector} />
                  </Item>

                  <Item label="За блоком:" name="contractBlock">
                    <SearchSelectBlock />
                  </Item>

                  <Item label="Без сахарка:" name="withoutSugar">
                    <Switch />
                  </Item>

                  <Item label="Номер договору" name="contractIndex">
                    <InputNumber />
                  </Item>

                  <Item label="Ім'я" name="partOfName">
                    <Input />
                  </Item>

                  <Item label="Куди платив" name="payBy" style={{ minWidth: '250px' }}>
                    <SearchSelectPayType />
                  </Item>

                  <Item label="Період оплати" name="paydayRange">
                    <DatePicker.RangePicker format="DD.MM.YYYY" />
                  </Item>
                </div>

                <div className="harvest-sort-wrapper">
                  <Box className="harvest-sort">
                    <Text className={'harvest-sort-text'}>По жнивах: </Text> &nbsp;
                    <Radio.Group
                      size="small"
                      onChange={({ target: { value } }) => {
                        setHarvestSort(value);
                        setContractSort(0);
                        setPayDaySort(0);
                        value ? setSortData({ sort: { i: value } }) : setSortData(undefined);
                      }}
                      value={harvestSort}
                    >
                      <Radio.Button value={0}>
                        <SmallDashOutlined />
                      </Radio.Button>
                      <Radio.Button value={-1}>
                        <SortDescendingOutlined />
                      </Radio.Button>
                      <Radio.Button value={1}>
                        <SortAscendingOutlined />
                      </Radio.Button>
                    </Radio.Group>
                  </Box>
                  <Box className="harvest-sort">
                    <Text className={'harvest-sort-text'}>По договорах: </Text> &nbsp;
                    <Radio.Group
                      size="small"
                      onChange={({ target: { value } }) => {
                        setHarvestSort(0);
                        setContractSort(value);
                        setPayDaySort(0);
                        value ? setSortData({ sort: { contractIndex: value } }) : setSortData(undefined);
                      }}
                      value={contractSort}
                    >
                      <Radio.Button value={0}>
                        <SmallDashOutlined />
                      </Radio.Button>
                      <Radio.Button value={-1}>
                        <SortDescendingOutlined />
                      </Radio.Button>
                      <Radio.Button value={1}>
                        <SortAscendingOutlined />
                      </Radio.Button>
                    </Radio.Group>
                  </Box>
                  <Box className="harvest-sort">
                    <Text className={'harvest-sort-text'}>По даті платежу: </Text> &nbsp;
                    <Radio.Group
                      size="small"
                      onChange={({ target: { value } }) => {
                        setHarvestSort(0);
                        setContractSort(0);
                        setPayDaySort(value);
                        value ? setSortData({ sort: { payday: value } }) : setSortData(undefined);
                      }}
                      value={payDateSort}
                    >
                      <Radio.Button value={0}>
                        <SmallDashOutlined />
                      </Radio.Button>
                      <Radio.Button value={-1}>
                        <SortDescendingOutlined />
                      </Radio.Button>
                      <Radio.Button value={1}>
                        <SortAscendingOutlined />
                      </Radio.Button>
                    </Radio.Group>
                  </Box>
                </div>

                <div className="search-btn-wrapper">
                  <Button style={{ width: 150 }} type="primary" htmlType="submit">
                    Пошук <SearchOutlined />
                  </Button>
                </div>
              </Form>
            </Card>
          )}
          <br />
        </>
      )}

      <Row style={{ justifyContent: 'center', marginBottom: '20px' }}>
        <Radio.Group options={tabs} optionType="button" value={tab} onChange={handleChangeTab} />
      </Row>

      <Spin spinning={loading} tip="Зачекайте" size="large">
        <Table
          dataSource={list}
          columns={columns}
          rowKey={(item) => item._id}
          rowClassName={(record) => (record.color ? 'client-row-' + record.color : '')}
          expandable={{
            expandRowByClick: true,
            expandIconColumnIndex: -1,
            expandedRowRender: (item) => <HarvestInfo data={item} />,
          }}
          pagination={{ total, current: currentPage }}
          onChange={handleTableChange}
        />
      </Spin>
    </div>
  );
};

export default Harvest;
