import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Popover, Divider } from 'antd';
import { setModal } from '../../store/commonReducer';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { CURRENCIES, PAY_PURPOSES, PAY_TYPES } from 'common_constants/business/';
import { CLIENT_INFO } from 'common_constants/modals';

const FondyHotlineInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);
  const users = useSelector((state) => state.common.users);
  const dispatch = useDispatch();

  const openClient = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data.client, fromContracts: true, interactionsBlocked: true }));
  };

  if (!data) {
    return 'Відсутня інформація';
  }

  return (
    <Card className="harvestInfo">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>Інформація про консультацію</h3>
        <span>
          <b>Дата:</b> {dayjs(data?.date).format('DD.MM.YYYY')}
        </span>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
          <span>
            <b>Початок:</b> {data?.startT}
          </span>

          <span>
            <b>Кінець:</b> {data?.endT}
          </span>
        </div>

        <span>
          <b>Вартість:</b> {data?.cost}
          {data.currency ? CURRENCIES[data?.sugar?.currency]?.symbol || '₴' : '₴'}
        </span>

        <span>
          <b>Філія:</b> {FILII[data.fil]}
        </span>

        <span>
          <b>Клієнт:</b> {data?.name}
        </span>

        <span>
          <b>Телефон:</b> {data?.phone}
        </span>
      </div>
      <Divider />

      <h3>Інформація про сахарок</h3>
      {data?.sugar && (
        <>
          <span>
            <b>Філія:</b> {FILII[data?.sugar?.fil]}
          </span>

          <span>
            <b>№ сахорку:</b> {data?.sugar?.i}
          </span>

          {data.name && (
            <span>
              <b>ПІБ: </b>
              <>
                {data.name} &nbsp;
                {data.client && <Link onClick={openClient}>подробиці</Link>}
              </>
            </span>
          )}

          {data.contractIndex && (
            <span>
              <b>№ договору:</b> {data.contractIndex}
            </span>
          )}

          <span>
            <b>Призначення платежу:</b> {PAY_PURPOSES?.[data?.sugar?.payfor]?.label}
          </span>

          <span>
            <b>Спосіб оплати:</b> {PAY_TYPES[data?.sugar?.payBy]?.label} {data.payByOther}
          </span>

          <span>
            <b>Сума:</b> {data.currency ? CURRENCIES[data?.sugar?.currency]?.symbol || '₴' : '₴'}
            {data?.sugar?.amount}
          </span>

          {typeof data?.sugar?.payforRest === 'number' ? (
            <span>
              <b>Залишок:</b> <span style={{ color: 'red' }}>₴{data?.sugar?.payforRest}</span>
            </span>
          ) : null}

          <span>
            <b>Дата оплати:</b> {dayjs(data?.sugar?.payday).format('DD.MM.YYYY HH:mm:ss')}
          </span>
        </>
      )}
      {!data?.sugar && <span>Відсутня інформація про сахарок</span>}
      <Divider />
      <h3>Інформація про гаманець</h3>
      {data?.payroll && (
        <>
          {data?.payroll?.usersAmount?.map((userAmount, index) => {
            const user = users[userAmount.user];
            return (
              <React.Fragment key={index}>
                {!!userAmount.user && (
                  <span>
                    <b>
                      Відсоток{' '}
                      <Popover content={<HoverableUser id={user?._id ?? '-'} />} arrow trigger="hover">
                        <Link>{user?.p ?? '-'}</Link>
                      </Popover>
                      :
                    </b>{' '}
                    {userAmount.percent}% - ₴{userAmount.amount}
                  </span>
                )}
              </React.Fragment>
            );
          })}
          {data?.payroll?.payrollToFil && (
            <span>
              <b>Консультація на філії:</b> {FILII[data?.payrollToFil]}
            </span>
          )}
          {data?.payroll?.createdAt && (
            <span>
              <b>Дата створення:</b> {dayjs(data?.payroll?.createdAt).format('DD.MM.YYYY HH:mm:ss')}
            </span>
          )}
          {/* {data.author && (
        <span>
          <b>Автор:</b>{' '}
          <Popover content={<HoverableUser id={users[data.author]?._id ?? '-'} />} arrow trigger="hover">
            <Link>{users[data.author]?.p ?? '-'}</Link>
          </Popover>
        </span>
      )} */}
        </>
      )}
      {!data?.payroll && <span>Відсутня інформація про гаманець</span>}
    </Card>
  );
};

export default FondyHotlineInfo;
