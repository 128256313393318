import { Button, Col, Pagination, Row, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { error, request } from '../../../../tools';
import InterimQualityAssessmentQuiestionnaire from './InterimQualityAssessmentQuiestionnaire';
import { usePagination } from '../../../../tools/hooks';
import Excel from '../../../../images/excel_logo.svg';
import { CSVLink } from 'react-csv';
import { LOGICAL_STANDARD_CHOISE_4_OPTIONS } from 'common_constants/business';
const InterimQualityAssessmentQuestionnaires = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const { totalItems, currentPage, pageSize, setTotalItems, setCurrentPage, setPageSize } = usePagination({});
  const [isLoading, setIsLoading] = useState(false);

  const getQuestionnaires = async (page, pageSize) => {
    try {
      const onSuccess = (result) => {
        setQuestionnaires(result.data);
        setTotalItems(result.meta.totalItems);
      };
      const onError = () => {
        error('Помилка!', 'Помилка завантаження проміжного анкетування!');
      };
      await request.post('/questionnaires/get/interimServiceQualityAssessmentQuestionnaires', { page, pageSize }, onSuccess, onError);
    } catch (e) {
      error('Помилка!', 'Помилка завантаження проміжного анкетування!');
    }
  };

  const [exportData, setExportData] = useState(null);
  const handleExport = async () => {
    setIsLoading(true);
    try {
      const onSuccess = (result) => {
        const exportData = [
          [
            'Індекс договору',
            'ПІБ',
            'Дата підписання',
            'Телефон',
            'Філія',
            'Куратор',
            'Блок',
            'Якість обслуговування',
            'Задоволення комунікацією',
            'Довіра компанії',
            'Пропозиції та скарги',
          ],
        ];
        result.data.forEach((item) => {
          exportData.push([
            item.contractIndex,
            item.name,
            item.sd,
            String(item.phone),
            item.fil,
            item.us,
            item.block,
            item.questionnaire.sq,
            LOGICAL_STANDARD_CHOISE_4_OPTIONS[item.questionnaire.sc],
            LOGICAL_STANDARD_CHOISE_4_OPTIONS[item.questionnaire.ts],
            item.questionnaire.pc,
          ]);
        });
        setExportData(exportData);
        setIsLoading(false);
      };
      const onError = () => {
        setIsLoading(false);
        error('Помилка!', 'Помилка отримання даних для експорту!');
      };
      await request.post('/questionnaires/export/interimServiceQualityAssessment', {}, onSuccess, onError);
    } catch (e) {
      error('Помилка!', 'Помилка отримання даних для експорту!');
    }
  };

  useEffect(() => {
    getQuestionnaires(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <Col className="questionnaires-wrapper">
      <Col>
        <Typography.Title style={{ textAlign: 'center' }} level={4}>
          Проміжне анкетування
        </Typography.Title>
        <Row className="buttons">
          {/* <Button onClick={() => getQuestionnaires(currentPage, pageSize)}>Пошук</Button> */}

          {exportData ? (
            <CSVLink filename="interim_assessment.csv" className="export-activity-excel__button" asyncOnClick={true} data={exportData}>
              <Button className="excel">
                <img className="excel-img" src={Excel} alt="Excel" /> Експорт проміжкової оцінки .csv
              </Button>
            </CSVLink>
          ) : (
            <Button
              onClick={handleExport}
              className="excel"
              style={{
                opacity: isLoading ? 0.6 : 1,
                pointerEvents: isLoading ? 'none' : 'all',
              }}
            >
              <img className="excel-img" src={Excel} alt="Excel" /> Генерувати експорт проміжкової оцінки
            </Button>
          )}
        </Row>
      </Col>
      <div className="questionnaires-list">
        {questionnaires.map((questionnaire) => (
          <InterimQualityAssessmentQuiestionnaire key={questionnaire.i} questionnaire={questionnaire} />
        ))}
      </div>
      <Row>
        <Pagination
          showSizeChanger
          onShowSizeChange={(_, size) => setPageSize(size)}
          total={totalItems}
          onChange={(page) => setCurrentPage(page)}
          pageSize={pageSize}
          current={currentPage}
          // pageSizeOptions={pageSizeOptions}
        />
      </Row>
    </Col>
  );
};

export default InterimQualityAssessmentQuestionnaires;
