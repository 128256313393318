import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, DatePicker, Button, InputNumber, Tag, AutoComplete, Card } from 'antd';
import { addSugar, setModal, setAssignmentsList } from '../../store/commonReducer';
import { error, request, success, warn } from '../../tools';
import { PAY_PURPOSES, PAY_TYPES, ROLES, COUNTRY_PHONE_CODES } from 'common_constants/business';
import SearchSelectPayTypeSugar from '../../components/SearchSelect/SearchSelectPayTypeSugar';
import SearchSelectPayPurp from '../../components/SearchSelect/SearchSelectPayPurp';
import SelectAssignment from '../../components/SelectAssignment/SelectAssignment';
import { updateAssignmentsListWithData } from '../ModalsHelpers';
import SearchSelectHotlines from '../../components/SearchSelect/SearchSelectHotlines';
import CountryPhCode from '../../components/CountryPhCode';

const AddSugar = () => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const FILII = useSelector((state) => state.common.FILII);
  const data = useSelector((state) => state.common.modal.data);
  const assignmentsList = useSelector((state) => state.common.assignmentsList);

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const blockManagerOnly = ROLES[userAuth.role] === ROLES.blockManager;

  const allPayTypes = inspector || blockManagerOnly;

  const [form] = Form.useForm();
  const timeoutRef = useRef(null);

  const [formData, setFormData] = useState({
    payBy: null,
    contractIndex: data ? data.reportBody.contractIndex : null,
    payfor: data ? PAY_PURPOSES[4].value : null,
    assignmentId: data ? data.assignmentData._id : null,
    assignmentIndex: data ? data.assignmentData.i : null,
    isFromAssignmentReport: !!data,
    hotlineId: null,
    ph: null,
  });
  const [hotlineDate, setHotlineDate] = useState();
  const [loading, setLoading] = useState(false);
  const [additionalRecords, setAdditionalRecords] = useState([]);
  const [localAssignmentsList, setLocalAssignmentsList] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [maxOptionWidth, setMaxOptionWidth] = useState(0);
  const [newClient, setNewClient] = useState(false);

  const handleFormSubmit = async () => {
    setLoading(true);

    let sugarData = {
      contractIndex: form.getFieldValue('contractIndex'),
      name: form.getFieldValue('name'),
      payday: form.getFieldValue('payday'),
      payfor: form.getFieldValue('payfor'),
      payforRest: form.getFieldValue('payforRest'),
      payBy: form.getFieldValue('payBy'),
      payByOther: form.getFieldValue('payByOther'),
      amount: form.getFieldValue('amount'),
      ph: form.getFieldValue('ph'),
      additionalRecords,
    };

    if (data) {
      sugarData.assignmentStatus = data.reportBody.assignmentStatus;
      sugarData.paymentStatus = data.reportBody.paymentStatus;
      sugarData.isFromAssignmentReport = !!data;
    }

    if (sugarData.payfor === PAY_PURPOSES[4].value) {
      sugarData.assignment = {
        assignmentId: formData.assignmentId,
        assignmentIndex: formData.assignmentIndex,
      };
      sugarData.isFromAssignmentReport = true;
      // sugarData.assignmentStatus = '1';
      // sugarData.paymentStatus = '1';
    } else if (sugarData.payfor === PAY_PURPOSES[5].value) {
      sugarData.hotlineId = form.getFieldValue('hotlineId');
    }
    if (sugarData.payfor === PAY_PURPOSES[8].value || sugarData.payfor === PAY_PURPOSES[9].value) {
      sugarData.ph = form.getFieldValue('ph');
      sugarData.countryPhCode = formData?.countryPhCode;
    }

    await request.post(
      '/sugar/addSugar',
      sugarData,
      ({ sugar, report }) => {
        success('', `Транзакція була успішно додана.`);
        dispatch(setModal());
        setLoading(false);
        if (data) {
          const sugarData = { amount: sugar[0].amount, index: sugar[0].i };
          const updatedAssignments = updateAssignmentsListWithData(assignmentsList, data.assignmentData._id, { report }, sugarData);
          dispatch(setAssignmentsList(updatedAssignments));
        }
        dispatch(addSugar(sugar));
      },
      () => {
        error('', `Помилка при додаванні.`);
        setLoading(false);
      },
    );
  };

  const onContractFiled = (contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;
      setFormData({ ...formData, contractIndex });
      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          if (!res.data) {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            return;
          }
          Modal.confirm({
            content: res.data.client ? "Використати ім'я: " + res.data.client + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;
              form.setFieldsValue({ name: res.data.client });
              form.setFieldsValue({ ph: res.data.ph });
              setFormData({
                ...formData,
                contractIndex,
                ph: res.data.ph,
                name: res.data.client,
              });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 1000);
  };

  const onNameFiled = (value) => {
    clearTimeout(timeoutRef.current);
    setNameSuggestions([]);

    timeoutRef.current = setTimeout(() => {
      if (value.length < 5) return;

      request.post(
        '/sugar/getContractByName',
        { n: value },
        (res) => {
          if (res.data && res.data.length > 0) {
            setNameSuggestions(
              res.data
                .sort((a, b) => (a.ad ? 1 : -1))
                .map((contract) => ({
                  value: (
                    <span>
                      {contract.name} - {FILII[contract.fil]} - {contract.i} {contract.ad && <Tag color="gold">A</Tag>}
                    </span>
                  ),
                  contract: contract,
                })),
            );
          } else {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            setNameSuggestions([]);
          }
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 800);
  };

  const onContractFiledAdditional = (index, contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;

      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          Modal.confirm({
            content: res.data.client ? "Використати ім'я: " + res.data.client + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;

              const updatedRecords = [...additionalRecords];
              updatedRecords[index] = {
                ...updatedRecords[index],
                contractIndex: contractIndex,
                name: res.data.client,
              };
              setAdditionalRecords(updatedRecords);
              form.setFieldsValue({ [`name${index}`]: res.data.client });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 1000);
  };

  const onNameFiledAdditional = (index, value) => {
    clearTimeout(timeoutRef.current);
    setNameSuggestions([]);

    timeoutRef.current = setTimeout(() => {
      if (value.length < 5) return;

      request.post(
        '/sugar/getContractByName',
        { n: value },
        (res) => {
          if (res.data && res.data.length > 0) {
            setNameSuggestions(
              res.data
                .sort((a, b) => (a.ad ? 1 : -1))
                .map((contract) => ({
                  value: (
                    <span>
                      {contract.name} - {FILII[contract.fil]} - {contract.i} {contract.ad && <Tag color="gold">A</Tag>}
                    </span>
                  ),
                  contract: contract,
                  index: index,
                })),
            );
          } else {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            setNameSuggestions([]);
          }
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 800);
  };

  const handleSelectName = (value, option, index) => {
    const selectedContract = option.contract;
    handleContractSelection(selectedContract, index);
  };

  const handleContractSelection = async (selectedContract, index) => {
    try {
      if (index >= 0 && index < additionalRecords.length) {
        const updatedRecords = [...additionalRecords];
        updatedRecords[index] = {
          ...updatedRecords[index],
          contractIndex: selectedContract.i,
          name: selectedContract.name,
        };
        setAdditionalRecords(updatedRecords);
        form.setFieldsValue({ [`contractIndex${index}`]: selectedContract.i });
        form.setFieldsValue({ [`name${index}`]: selectedContract.name });
      } else {
        form.setFieldsValue({
          contractIndex: selectedContract.i,
          name: selectedContract.name,
          ph: selectedContract.ph,
        });
      }
      setFormData({
        ...formData,
        contractIndex: selectedContract.i,
        ph: selectedContract.ph,
      });
    } catch (error) {
      console.error('Error handling contract selection:', error);
    }
  };
  const handleHotlineIdChange = (value) => {
    form.setFieldValue('hotlineId', value);
  };

  const handlePayByChange = (value) => {
    setFormData({
      ...formData,
      payBy: value,
    });
  };

  const handlePayForChange = (value) => {
    if (value === 8 || value === 9) {
      setFormData({
        ...formData,
        payfor: value,
        countryPhCode: COUNTRY_PHONE_CODES.UA,
      });
      return;
    }
    setFormData({
      ...formData,
      payfor: value,
    });
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const onlyNumbers = input.replace(/\D/g, '').slice(0, 10);

    setFormData({
      ...formData,
      ph: onlyNumbers ? onlyNumbers : null,
    });
  };
  const handleCountryPhCodehange = (value) => {
    setFormData({
      ...formData,
      countryPhCode: value,
    });
  };

  const handleHotlineDateChange = (value) => {
    setHotlineDate(value);
    form.setFieldValue('hotlineId', value);
  };

  const handleAssignmentDateChange = (value) => {
    setFormData({
      ...formData,
      assignmentDate: value,
    });
  };

  useEffect(() => {
    if (data) {
      onContractFiled(data.assignmentData.contractNumber);
    }
  }, []);

  useEffect(() => {
    return () => handleHotlineIdChange(null);
  }, [hotlineDate]);

  useEffect(() => {
    if (formData.contractIndex && formData.payfor === PAY_PURPOSES[4].value && formData.assignmentDate) {
      setLoading(true);
      request.post(
        '/assignment/getForSugar',
        { targetDate: formData.assignmentDate, contractNumberFilter: formData.contractIndex },
        ({ assignments }) => {
          setLocalAssignmentsList(assignments);
          setLoading(false);
        },
      );
    }
  }, [formData.contractIndex, formData.payfor, formData.assignmentDate]);

  const handleAddRecord = () => {
    setAdditionalRecords((prevRecords) => [...prevRecords, { contractIndex: '', name: '', amount: '' }]);
  };

  const handleRecordChange = (index, field, value) => {
    const updatedRecords = [...additionalRecords];
    updatedRecords[index][field] = value;
    setAdditionalRecords(updatedRecords);
  };

  const handleRemoveRecord = (index) => {
    setAdditionalRecords((prevRecords) => {
      const updatedRecords = prevRecords.filter((_, i) => i !== index);
      return updatedRecords;
    });
  };

  useEffect(() => {
    const lengths = [];
    nameSuggestions.forEach((suggestion) => {
      let suggestionLength = {
        nameLength: suggestion.contract.name.length || 0,
        filLength: FILII[suggestion.contract.fil]?.length || 0,
      };
      lengths.push(suggestionLength.filLength + suggestionLength.nameLength);
    });

    const maxLength = Math.max(...lengths);

    if (maxLength >= 50) {
      setMaxOptionWidth(maxLength * 10);
    } else {
      setMaxOptionWidth(maxLength * 10 + 40);
    }
  }, [nameSuggestions]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  const handleBlur = () => {
    setLoading(true);
    if (formData?.ph.length !== 10) {
      warn('', 'Телефон не коректний');
      setLoading(false);
      return;
    }
    request.post(
      '/clients/findByPhoneSelect',
      { ph: formData?.ph, countryPhCode: formData?.countryPhCode, exact: true },
      ({ data }) => {
        if (data) {
          success('', 'Клієнта знайдено');
          setNewClient(false);
          setLoading(false);

          return;
        }
        setNewClient(true);
        warn('', 'Клієнта не знайдено');
        setLoading(false);
      },
      error,
    );
    setLoading(false);
  };

  useEffect(() => {
    additionalRecords.forEach((record, index) => {
      form.setFieldsValue({
        [`contractIndex${index}`]: record.contractIndex,
        [`name${index}`]: record.name,
        [`amount${index}`]: record.amount,
      });
    });
  }, [additionalRecords, form]);

  return (
    <Modal title="Додати транзакцію" open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <Form
          className="addSugar-form"
          form={form}
          onFinish={handleFormSubmit}
          initialValues={formData}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            label="№ Договору"
            name="contractIndex"
            rules={[
              {
                required:
                  form.getFieldValue('payfor') &&
                  form.getFieldValue('payfor') !== 5 &&
                  form.getFieldValue('payfor') !== 8 &&
                  form.getFieldValue('payfor') !== 9 &&
                  form.getFieldValue('payfor') !== 50,
                message: "Обов'язково для заповнення",
              },
            ]}
          >
            <InputNumber disabled={!!data} min={1} onChange={onContractFiled} />
          </Form.Item>
          <Form.Item
            label="ПІБ"
            name="name"
            rules={[
              {
                required: form.getFieldValue('payfor') && form.getFieldValue('payfor') !== 50,
                message: 'Будь ласка введіть прізвище клієнта!',
              },
              { min: 5, message: 'Мінімум 5 символів!' },
            ]}
          >
            <AutoComplete
              options={nameSuggestions.map((suggestion) => ({
                label: suggestion.value,
                value: suggestion.value,
                contract: suggestion.contract,
              }))}
              onSelect={handleSelectName}
              onSearch={onNameFiled}
              placeholder="Пошук за іменем"
              dropdownMatchSelectWidth={window.screen.width < 768 ? 350 : maxOptionWidth}
              allowClear
              dropdownRender={(menu) => <div className='add-sugar-modal-dropdown'>{menu}</div>}
            />
          </Form.Item>
          <Form.Item label="Сума" name="amount" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <InputNumber onWheel={(e) => e.preventDefault()} onKeyDown={handleKeyPress} style={{ width: '100%' }} />
          </Form.Item>
          <Card
            title="Додаткові транзакції"
            style={{ marginBottom: '20px' }}
            extra={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleAddRecord}>+</Button>
              </div>
            }
          >
            {additionalRecords.map((record, index) => (
              <div
                key={index}
                style={{
                  marginBottom: 24,
                  paddingBottom: 16,
                  borderBottom: index < additionalRecords.length - 1 ? '1px solid #d9d9d9' : 'none',
                }}
              >
                <Form.Item
                  label="№ Договору"
                  name={`contractIndex${index}`}
                  rules={[
                    {
                      required:
                        form.getFieldValue('payfor') &&
                        form.getFieldValue('payfor') !== 5 &&
                        form.getFieldValue('payfor') !== 8 &&
                        form.getFieldValue('payfor') !== 9 &&
                        form.getFieldValue('payfor') !== 50,
                      message: "Обов'язково для заповнення",
                    },
                  ]}
                >
                  <InputNumber value={record.contractIndex} onChange={(value) => onContractFiledAdditional(index, value)} />
                </Form.Item>

                <Form.Item
                  label="ПІБ"
                  name={`name${index}`}
                  rules={[
                    {
                      required: form.getFieldValue(`payfor`) && form.getFieldValue(`payfor`) !== 50,
                      message: 'Будь ласка введіть прізвище клієнта!',
                    },
                    { min: 5, message: 'Мінімум 5 символів!' },
                  ]}
                >
                  <AutoComplete
                    options={nameSuggestions.map((suggestion) => ({
                      label: suggestion.value,
                      value: suggestion.value,
                      contract: suggestion.contract,
                      index: suggestion.index,
                    }))}
                    onSelect={(value, option) => handleSelectName(value, option, index)}
                    onSearch={(value) => onNameFiledAdditional(index, value)}
                    placeholder="Пошук за іменем"
                    dropdownMatchSelectWidth={window.screen.width < 768 ? 350 : maxOptionWidth}
                    allowClear
                  />
                </Form.Item>

                <Form.Item label="Сума" name={`amount${index}`} rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
                  <InputNumber
                    onKeyDown={handleKeyPress}
                    onWheel={(e) => e.preventDefault()}
                    value={Number(record.amount)}
                    onChange={(value) => handleRecordChange(index, 'amount', value)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="link" onClick={() => handleRemoveRecord(index)}>
                    Видалити
                  </Button>
                </div>
              </div>
            ))}
          </Card>
          <Form.Item label="Дата оплати" name="payday" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Призначення" name="payfor" rules={[{ required: true, message: 'Будь ласка введіть призначення платежу!' }]}>
            <SearchSelectPayPurp disabledState={!!data} onChange={handlePayForChange} />
          </Form.Item>
          {formData.payfor === PAY_PURPOSES[4].value && !data && (
            <Form.Item label="Дата виходу" name="assignmentDate" rules={[{ required: true, message: 'Будь ласка введіть дату виходу!' }]}>
              <DatePicker onChange={handleAssignmentDateChange} />
            </Form.Item>
          )}
          {formData.payfor === PAY_PURPOSES[4].value && !data && (
            <Form.Item
              className="sugar-assignment"
              label="Вихід"
              name="assignmentId"
              rules={[{ required: true, message: 'Будь ласка оберіть вихід' }]}
              style={{ height: '230px', padding: '0' }}
            >
              <SelectAssignment form={form} formData={formData} setFormData={setFormData} assignmentsList={localAssignmentsList} />
            </Form.Item>
          )}
          {formData.payfor === PAY_PURPOSES[3].value && (
            <Form.Item
              label="Залишок"
              name="payforRest"
              rules={[{ required: formData.payfor === PAY_PURPOSES[3].value, message: "Обов'язково для заповнення" }]}
            >
              <InputNumber onKeyDown={handleKeyPress} />
            </Form.Item>
          )}
          {formData.payfor === PAY_PURPOSES[5].value && (
            <Form.Item label="Дата консультації" name="hotlineday">
              <DatePicker onChange={handleHotlineDateChange} value={hotlineDate} allowClear />
            </Form.Item>
          )}
          {(formData.payfor === PAY_PURPOSES[8].value || formData.payfor === PAY_PURPOSES[9].value) && (
            <Form.Item
              name="ph"
              label="Телефон"
              rules={[
                {
                  required: form.getFieldValue('payfor') === 8 || form.getFieldValue('payfor') === 9,
                  message: "Обов'язково для заповнення",
                },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <CountryPhCode
                  value={formData?.countryPhCode ? formData?.countryPhCode : COUNTRY_PHONE_CODES.UA}
                  onChange={handleCountryPhCodehange}
                />

                <Input
                  aria-label="Телефон"
                  value={formData?.ph ? formData?.ph : ''}
                  onChange={(e) => handlePhoneChange(e)}
                  maxLength={10}
                  placeholder="Введіть номер телефону"
                  onBlur={handleBlur}
                />
              </div>
            </Form.Item>
          )}
          {newClient && <p className="newClient-warn">Зверніть увагу. За цим номером буде створено нового клієнта!</p>}

          {!!hotlineDate && (
            <Form.Item label="Консультація" name="hotlineId">
              <SearchSelectHotlines fil={userAuth.fil} date={hotlineDate} onChange={handleHotlineIdChange} value={formData.hotlineId} />
            </Form.Item>
          )}
          <Form.Item label="Спосіб оплати" name="payBy" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <SearchSelectPayTypeSugar onChange={handlePayByChange} allPayTypes={allPayTypes} />
          </Form.Item>
          {formData.payBy === PAY_TYPES.other.value && (
            <Form.Item label="Куди платив уточнення" name="payByOther" rules={[{ required: true, message: 'Будь ласка вкажіть уточнення!' }]}>
              <Input />
            </Form.Item>
          )}
          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddSugar;
