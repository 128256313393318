import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { CSVLink } from 'react-csv';

import { setModal } from '../../store/commonReducer';
import { error, request } from '../../tools';
import Excel from '../../images/excel_logo.svg';

import './ExportContractsPointsPlanStat.scss';

const ExportContractsPointsPlanStat = () => {
  const dispatch = useDispatch();
  const filii = useSelector((state) => state.common.filii);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(setModal());
  };

  const onGenerate = async () => {
    setIsLoading(true);

    await request.post(
      '/contracts/exportContractPointsByFil',
      {},
      ({ data }) => {
        setData(data);
      },
      error,
    );

    setIsLoading(false);
  };

  const csvData = [['Філія', 'Відсоток, %', 'Чи виконаний план, так/ні', 'штраф, %']];

  data?.forEach((item) => {
    if (typeof item?._id == 'string') return;

    const currentFil = filii?.find((fil) => fil.i == item?._id);

    csvData.push([currentFil.name, item?.percentage, item?.percentage >= 75 ? 'Так' : 'Ні', item?.percentage >= 75 ? 0 : 100 - item?.percentage]);
  });

  return (
    <Modal
      title="Експорт статистики виконаного плану договорів для кожної філії"
      open
      onCancel={handleCloseModal}
      footer={null}
      className="export-hotline export-plan-points-modal"
    >
      <div className="wrapper">
        {data ? (
          <CSVLink asyncOnClick={true} data={csvData}>
            <Button className="excel">
              <img className="excel-img" src={Excel} alt="Excel" /> Скачати .csv
            </Button>
          </CSVLink>
        ) : (
          <Button type="primary" onClick={onGenerate} loading={isLoading}>
            Генерувати
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ExportContractsPointsPlanStat;
