import React, { Fragment } from 'react';
import { Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import openai from '../../images/openai.svg';
import { selectTypingEffect, setThinking } from '../../../../store/aiReducer';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
import TypingEffect from '../TypingEffect/TypingEffect';

const Message = React.memo(({ message, downloadUrl, currentUser, index, array, scrollToBottom, selectedDialogueId }) => {
  const dispatch = useDispatch();
  const isTypingEffectActive = useSelector(selectTypingEffect);
  const socket = useSelector((state) => state.common.socket);
  const isUserMessage = message?.role === 'user';
  const isAssistantMessage = message?.role === 'assistant';
  const isLastMessage = array.length - 1 === index;
  const isNeedTypingEffect = isAssistantMessage && isLastMessage && isTypingEffectActive;
  const downloadLink = (
    <span>
      <a href={downloadUrl} download="document.docx">
        Завантажити
      </a>
      &nbsp;&nbsp;
    </span>
  );

  let messageContent = message.content;
  let filesListContent = null;

  const hadleDownload = (name) => {
    dispatch(setThinking({ dialogueId: selectedDialogueId, thinking: true }));
    socket.emit('continue-dialogue', { dialogueId: selectedDialogueId, query: name });
  };

  if (message?.filesList) {
    messageContent = null;
    const rows = message.content.split('\n');
    const filesToGet = rows.map((row) => {
      const [id, name] = row.split('***');
      return { id, name };
    });
    filesListContent = (
      <Row className="files-list" style={{ flexDirection: 'column' }}>
        {filesToGet.map((file) => {
          const { id, name } = file;
          return (
            <Row key={id}>
              <a href="#" onClick={() => hadleDownload(name)}>
                {name}
              </a>
            </Row>
          );
        })}
      </Row>
    );
  }

  if (downloadUrl) {
    messageContent = downloadLink;
  }

  if (typeof messageContent === 'string' && messageContent.includes('Відсутня інформація:')) {
    const list = messageContent
      .split('Відсутня інформація:')[1]
      .split(';')
      .map((item, index) => (
        <Fragment key={index}>
          <span>{item}</span>
          <br />
        </Fragment>
      ));
    messageContent = (
      <div>
        <b>Відсутня інформація:</b>
        <br />
        {list}
      </div>
    );
  }

  // const typingEffectProps = {
  //   text: message.content,
  //   scrollToBottom,
  // };

  return (
    <Row key={index} className={`message ${message?.role}`}>
      <Row className="header">
        <Row className="avatar">
          {isUserMessage && <UserAvatar user={currentUser} />}
          {isAssistantMessage && <img src={openai} alt="openai" />}
        </Row>
        <Row className="label">
          {isUserMessage && <Row>{currentUser?.name}</Row>}
          {isAssistantMessage && <Row>ChatGPT</Row>}
        </Row>
      </Row>
      <Row className="content">
        {/* {downloadUrl && downloadLink} */}
        {/* {isNeedTypingEffect ? <TypingEffect {...typingEffectProps} /> : message.content} */}
        {/* {filesListContent || message.content} */}
        {filesListContent}
        {message.documentName ? message.documentName.replace(/^\*+|\*+$/g, '') + ' - ' : null}
        {messageContent}
      </Row>
    </Row>
  );
});

export default Message;
