import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CONTRACT_CREATE_CALL } from 'common_constants/modals';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { setModal } from '../../../store/commonReducer';

const ContractCallsInfo = ({ contractId }) => {
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.common.contracts);

  const currentContract = contracts?.find((item) => item._id === contractId);

  const handleOpenCallCreate = () => {
    dispatch(setModal({ name: CONTRACT_CREATE_CALL, data: { contractId } }));
  };

  return (
    <div className="contract-call-info">
      <Button type="primary" onClick={handleOpenCallCreate} icon={<PlusOutlined />}>
        Додати дзвінок
      </Button>
      <div className="call-info-wrapper">
        {currentContract?.calls?.map((item, index) => (
          <div className="call-info" key={index}>
            <p>Номер: {item.i}</p>
            <p>Дата: {dayjs(item.date).format('DD.MM.YYYY')}</p>
            <p>Тривалість: {item.seconds}с.</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContractCallsInfo;
