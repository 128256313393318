import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Input, DatePicker, Radio, notification, Form, Typography, Select } from 'antd';
import { SolutionOutlined, UsbOutlined } from '@ant-design/icons';
import {
  TOWNS,
  FORM_OF_BUSINESS,
  JOBS,
  SOC_STATUS,
  WORK_STATES,
  HOBBIES,
  RELATIONS,
  CLIENT_COLOR,
  ROLES,
  COUNTRY_PHONE_CODES,
} from 'common_constants/business';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';
import { Checkbox } from 'antd';

import { setModal, setCurrentClient, editClient, setContractsList } from '../../store/commonReducer';
import SearchSelect from '../../components/SearchSelect';

import './styles.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';
import CountryPhCode from '../../components/CountryPhCode';

const { Text } = Typography;

const NewClient = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.common.currentClient);
  const { prev, fromContracts } = useSelector((state) => state.common.modal);
  const { role } = useSelector((state) => state.common.userAuth);
  const contracts = useSelector((state) => state.common.contracts);
  const FILII = useSelector((state) => state.common.FILII);

  const inspector = ROLES[role] <= ROLES.inspector;
  const manager = ROLES[role] <= ROLES.manager;

  const [pib, setPib] = useState(client?.n ?? prev?.data?.name);
  const isPibValidInitState = (validatePib(pib) && !inspector) || inspector;
  const [isPibValid, setIsPibValid] = useState(isPibValidInitState);
  const [affiliate, setAffiliate] = useState(client?.f ?? prev?.data?.affiliate);
  const [color, setColor] = useState(client?.color ?? prev?.data?.color);
  const [birthday, setBirthday] = useState(client?.b ? dayjs(client.b) : null);
  const [town, setTown] = useState(client?.t ?? 0);
  const [address, setAddress] = useState(client?.a);
  const [sex, setSex] = useState(client?.se ?? 1);
  const [disabledPeople, setDisabledPeople] = useState(client?.disabledPeople ?? false);
  const [passportType, setPassportType] = useState(client?.pt);
  const [passportNo, setPassportNo] = useState(client?.pn);
  const [passportIssuedBy, setPassportIssuedBy] = useState(client?.pkv);
  const [passportDateOfIssue, setPassportDateOfIssue] = useState(client?.pdv ? dayjs(client.pdv) : null);
  const [passportDateOfExpiry, setPassportDateOfExpiry] = useState(client?.pdd ? dayjs(client.pdd) : null);
  const [inn, setInn] = useState(client?.inn);

  const [form, setForm] = useState(client?.u ?? 0);
  const [phone, setPhone] = useState(client?.ph ?? prev?.data?.phone);
  const [countryPhCode, setCountryPhCode] = useState(client?.countryPhCode ?? COUNTRY_PHONE_CODES.UA);
  const [phoneVerified, setPhoneVerified] = useState(client?.phoneVerified);

  const [error, setError] = useState(false);
  const [email, setEmail] = useState(client?.email);
  const [job, setJob] = useState(client?.j);
  const [socStatus, setSocStatus] = useState(client?.ss);
  const [workState, setWorkState] = useState(client?.ws);
  const [hobby, setHobby] = useState(client?.h);

  const [relation1, setRelation1] = useState(client?.r?.[0]?.name);
  const [relation2, setRelation2] = useState(client?.r?.[1]?.name);
  const [relation3, setRelation3] = useState(client?.r?.[2]?.name);
  const [relation1Phone, setRelation1Phone] = useState(client?.r?.[0]?.phone);
  const [relation2Phone, setRelation2Phone] = useState(client?.r?.[1]?.phone);
  const [relation3Phone, setRelation3Phone] = useState(client?.r?.[2]?.phone);

  const [ch1, setCh1] = useState(client?.ch1 ?? 1);
  const [ch2, setCh2] = useState(client?.ch2 ?? 1);
  const [ch3, setCh3] = useState(client?.ch3 ?? 1);
  const [ch4, setCh4] = useState(client?.ch4 ?? 1);
  const [ch5, setCh5] = useState(client?.ch5 ?? 1);
  const [ch6, setCh6] = useState(client?.ch6 ?? 1);

  const handlePibChange = (e) => {
    const isPibValidForNoneInspector = validatePib(e.target.value) && !inspector;

    if (isPibValidForNoneInspector || inspector) {
      setIsPibValid(true);
      setError(false);
    } else {
      setIsPibValid(false);
      setError(true);
    }
    setPib(e.target.value);
  };

  const onSubmit = () => {
    if (!phone || !pib) return setError(true);

    const relations = [];
    if (relation1 != null || relation1Phone) relations.push({ name: relation1, phone: relation1Phone });
    if (relation2 != null || relation2Phone) relations.push({ name: relation2, phone: relation2Phone });
    if (relation3 != null || relation3Phone) relations.push({ name: relation3, phone: relation3Phone });

    const _client = {
      n: pib,
      color,
      b: birthday?.toISOString(),
      t: town,
      a: address,
      se: sex,
      pt: passportType,
      pn: passportNo,
      pkv: passportIssuedBy,
      pdv: passportDateOfIssue?.toISOString(),
      pdd: passportDateOfExpiry?.toISOString(),
      inn: Number(inn),
      u: form,
      ph: phone,
      countryPhCode: countryPhCode,
      phoneVerified: phoneVerified,
      f: affiliate,
      email: email,
      j: job,
      ss: socStatus,
      ws: workState,
      h: hobby,
      r: relations.length ? relations : undefined,
      ch1,
      ch2,
      ch3,
      ch4,
      ch5,
      ch6,
      disabledPeople,
    };

    const editedClient = Object.entries(_client).reduce((acc, [key, value]) => {
      if (Array.isArray(value) && key === 'r') {
        acc[key] = value.map((relation) => {
          const updatedRelation = { ...relation };

          if (relation.name != null) {
            updatedRelation.nameEdited = true;
          }

          if (relation.phone != null && relation.phone !== '') {
            updatedRelation.phoneEdited = true;
          }

          return updatedRelation;
        });
      } else {
        acc[key] = value;
        if (value != null && value !== '') {
          acc[`${key}Edited`] = true;
        }
      }
      return acc;
    }, {});

    if (client?.selfRegistered) {
      _client.selfRegistered = client.selfRegistered;
      editedClient.selfRegistered = client.selfRegistered;
    }

    if (client?._id) {
      const token = window.localStorage.getItem('accessToken');
      _client._id = client._id;
      editedClient._id = client._id;

      return fetch(process.env.REACT_APP_API + '/clients/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        body: JSON.stringify(_client),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status && res.nameIsTooShort) {
            notification['warning']({
              message: 'Помилка!',
              description: res.nameIsTooShort ? 'Вкажіть ПІБ повністю.' : '',
            });
            return;
          }

          if (!res.status) {
            notification['warning']({
              message: 'Помилка!',
              description: res.duplicatePhone ? 'Клієнт з таким номером вже існує' : '',
            });
            return;
          }

          if (fromContracts) {
            const updatedContracts = contracts.map((contract) => {
              if (contract.client[0]._id === editedClient._id) {
                const updatedClient = [{ ...contract.client[0], ...editedClient }];
                const updatedContract = { ...contract, client: updatedClient };
                return updatedContract;
              }
              return contract;
            });

            dispatch(setContractsList(updatedContracts));
            const { name, data } = prev;
            const updatedData = { ...data, ...editedClient };
            dispatch(setModal({ name, data: updatedData, fromContracts }));
            dispatch(setCurrentClient({ ...editedClient }));
            dispatch(editClient({ ...editedClient }));
            return;
          }

          dispatch(setModal(prev));
          dispatch(setCurrentClient({ ...editedClient }));
          dispatch(editClient({ ...editedClient }));
        });
    }

    dispatch(setModal(prev));
    dispatch(setCurrentClient({ ...editedClient }));
  };

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);
    setPhone(validPhoneNumber);
  };

  return (
    <Modal
      open
      footer={null}
      className="add-client"
      title={(client?._id ? 'Змінюємо' : 'Додаємо') + ' клієнта'}
      onCancel={() => {
        if (!prev) {
          dispatch(setCurrentClient());
        }
        dispatch(setModal({ ...prev, prev: {} }));
      }}
    >
      <Text>
        ПІБ <span style={{ color: 'red' }}>*</span>
      </Text>
      <Form.Item validateStatus={isPibValid ? 'success' : 'error'}>
        <Input aria-label="ПІБ" value={pib} onInput={handlePibChange} disabled={client?.nEdited && !inspector} />
      </Form.Item>
      <Text>Філія</Text>
      <SearchSelect list={FILII} value={affiliate} onChange={(v) => setAffiliate(v)} disabled={client?.fEdited && !inspector} />
      <br />
      <br />
      <Text>Колір клієнта</Text>
      <SearchSelect
        list={[...Object.values(CLIENT_COLOR).map((i) => i.name)]}
        value={color}
        onChange={(v) => setColor(v)}
        disabled={client?.colorEdited && !inspector}
      />
      <br />
      <br />
      <div className="phone-wrapper">
        <div className="countryPhCode-wrapper">
          <Text>Код країни</Text>
          <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} />
        </div>
        <div>
          <Text>
            Телефон <span style={{ color: 'red' }}>*</span>
            {client?._id && <span className="phone-warning">(При зміні переконатися, що не буде повторів)</span>}
          </Text>
          <Input aria-label="Телефон" value={phone} onChange={(e) => handlePhoneNumberInput(e)} disabled={client?.phEdited && !inspector} />
        </div>{' '}
      </div>
      {manager && !Object.values(COUNTRY_PHONE_CODES).includes(client?.countryPhCode) && (
        <div className="phoneVerified-wrapper">
          <span className="phoneVerified">Підтвердити телефон </span>
          <Checkbox checked={phoneVerified} onChange={(e) => setPhoneVerified(e.target.checked)} disabled={client?.phoneVerified} />
        </div>
      )}

      <br />
      <br />
      <Text>Дата народження</Text>
      <DatePicker locale={locale} value={birthday} onChange={(value) => setBirthday(value)} disabled={client?.bEdited && !inspector} />
      <br />
      <br />
      <Text>Місто народження</Text>
      <SearchSelect list={TOWNS} value={town} onChange={(v) => setTown(v)} disabled={client?.tEdited && !inspector} />
      <br />
      <br />
      <Text>Адреса</Text>
      <Input aria-label="Адреса" value={address} onChange={(e) => setAddress(e.target.value)} disabled={client?.aEdited && !inspector} />
      <br />
      <br />
      <Text>Стать</Text>
      <Radio.Group value={sex} onChange={(e) => setSex(e.target.value)} disabled={client?.seEdited && !inspector}>
        <Radio value={1}>ч</Radio>
        <Radio value={2}>ж</Radio>
      </Radio.Group>
      <br />
      <br />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={disabledPeople}
          onChange={(e) => setDisabledPeople(e.target.checked)}
          disabled={client?.disabledPeopleEdited && !inspector}
        />
        <UsbOutlined style={{ marginLeft: 8 }} />
        <Text style={{ marginLeft: 8 }}>Не може користуватися єАдвокат</Text>
      </div>
      <Text>(буде перевірятись)</Text>

      <br />

      <br />
      <Text strong>
        <SolutionOutlined /> Паспортні данні:
      </Text>
      <br />
      <br />
      <Text>Тип</Text>
      <SearchSelect
        list={['ID-карта', 'Паспорт-книжечка']}
        value={passportType}
        placeholder="Оберіть тип паспорта"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        onChange={(value) => setPassportType(value)}
      />
      <br />
      <br />
      <Text>Серія та номер</Text>
      <Input aria-label="Серія та номер" value={passportNo} onChange={(e) => setPassportNo(e.target.value)} />
      <br />
      <br />
      <Text>Ким виданий</Text>
      <Input aria-label="Ким виданий" value={passportIssuedBy} onChange={(e) => setPassportIssuedBy(e.target.value)} />
      <br />
      <br />
      <Text>Дата видачі</Text>
      <DatePicker value={passportDateOfIssue} locale={locale} format="DD-MM-YYYY" onChange={(value) => setPassportDateOfIssue(value)} />
      <br />
      <br />
      <Text>Дійсний до</Text>
      <DatePicker value={passportDateOfExpiry} locale={locale} format="DD-MM-YYYY" onChange={(value) => setPassportDateOfExpiry(value)} />
      <br />
      <br />
      <Text>ІПН</Text>
      <Input aria-label="ІПН" type="number" value={inn} onChange={(e) => setInn(e.target.value)} />
      <br />
      <br />
      <hr />
      <hr />
      <br />
      <Text>ФО/ФОП/ЮО</Text>
      <SearchSelect list={FORM_OF_BUSINESS} value={form} onChange={(v) => setForm(v)} disabled={client?.uEdited && !inspector} />
      <br />
      <br />
      <Text>E-mail</Text>
      <Input aria-label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} disabled={client?.emailEdited && !inspector} />
      <br />
      <br />
      <Text>Професія</Text>
      <SearchSelect list={JOBS} value={job} placeholder="Оберіть професію" onChange={(v) => setJob(v)} disabled={client?.jEdited && !inspector} />
      <br />
      <br />
      <Text>Соц статус</Text>
      <SearchSelect
        list={SOC_STATUS}
        value={socStatus}
        placeholder="Оберіть Соц статус"
        onChange={(v) => setSocStatus(v)}
        disabled={client?.ssEdited && !inspector}
      />
      <br />
      <br />
      <Text>Службове становище</Text>
      <SearchSelect
        list={WORK_STATES}
        value={workState}
        placeholder="Оберіть Службове становище"
        onChange={(v) => setWorkState(v)}
        disabled={client?.wsEdited && !inspector}
      />
      <br />
      <br />
      <Text>Хобі</Text>
      <SearchSelect list={HOBBIES} value={hobby} placeholder="Оберіть Хобі" onChange={(v) => setHobby(v)} disabled={client?.hEdited && !inspector} />
      <br />
      <br />
      <Text>Зв'язок 1</Text>
      <SearchSelect
        list={RELATIONS}
        value={relation1}
        placeholder="Оберіть Зв'язок 1"
        onChange={(v) => setRelation1(v)}
        disabled={client?.r && client?.r[0]?.nameEdited && !inspector}
      />
      <br />
      <br />
      <Text>Телефон 1</Text>
      <Input
        aria-label="Телефон 1"
        value={relation1Phone}
        onChange={(e) => setRelation1Phone(e.target.value)}
        disabled={client?.r && client?.r[0]?.phoneEdited && !inspector}
      />
      <br />
      <br />
      <Text>Зв'язок 2</Text>
      <SearchSelect
        list={RELATIONS}
        value={relation2}
        placeholder="Оберіть Зв'язок 2"
        onChange={(v) => setRelation2(v)}
        disabled={client?.r && client?.r[1]?.nameEdited && !inspector}
      />
      <br />
      <br />
      <Text>Телефон 2</Text>
      <Input
        aria-label="Телефон 2"
        value={relation2Phone}
        onChange={(e) => setRelation2Phone(e.target.value)}
        disabled={client?.r && client?.r[1]?.phoneEdited && !inspector}
      />
      <br />
      <br />
      <Text>Звя'зок 3</Text>
      <SearchSelect
        list={RELATIONS}
        value={relation3}
        placeholder="Оберіть Зв'язок 3"
        onChange={(v) => setRelation3(v)}
        disabled={client?.r && client?.r[2]?.nameEdited && !inspector}
      />
      <br />
      <br />
      <Text>Телефон 3</Text>
      <Input
        aria-label="Телефон 3"
        value={relation3Phone}
        onChange={(e) => setRelation3Phone(e.target.value)}
        disabled={client?.r && client?.r[2]?.phoneEdited && !inspector}
      />
      <br />
      <br />
      <Text> інтелект {ch1}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch1}
        onChange={(e) => setCh1(e.target.value)}
        disabled={client?.ch1Edited && !inspector}
      />
      <br />
      <br />
      <Text>Критичність мислення {ch2}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch2}
        onChange={(e) => setCh2(e.target.value)}
        disabled={client?.ch2Edited && !inspector}
      />
      <br />
      <br />
      <Text>Ініціативність {ch3}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch3}
        onChange={(e) => setCh3(e.target.value)}
        disabled={client?.ch3Edited && !inspector}
      />
      <br />
      <br />
      <Text>Поступливість {ch4}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch4}
        onChange={(e) => setCh4(e.target.value)}
        disabled={client?.ch4Edited && !inspector}
      />
      <br />
      <br />
      <Text>Дружелюбність {ch5}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch5}
        onChange={(e) => setCh5(e.target.value)}
        disabled={client?.ch5Edited && !inspector}
      />
      <br />
      <br />
      <Text>Емоційність {ch6}</Text>
      <input
        className="no_swipe"
        type="range"
        min="1"
        max="4"
        value={ch6}
        onChange={(e) => setCh6(e.target.value)}
        disabled={client?.ch6Edited && !inspector}
      />
      <br />
      <br />
      {/* Коментар
        <Input.TextArea rows="8" value={comment} onChange={(e) => setComment(e.target.value)} />
        <br /><br /> */}
      {error && <span className="error"> Не додано телефон або ім`я</span>}
      <Button type="primary" className="submit" onClick={onSubmit}>
        {client?._id ? 'Змінити' : 'Додати'} клієнта
      </Button>
    </Modal>
  );
};

function validatePib(pib) {
  const arrayFromName = pib?.split(' ');
  const threeWordsRequired = arrayFromName?.length >= 3;
  const threeLettersRequired = arrayFromName?.every((word) => word.length >= 3);
  return threeWordsRequired && threeLettersRequired;
}

export default NewClient;
