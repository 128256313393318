import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Message } from '..';
import { selectThinking } from '../../../../store/aiReducer';
import openai from '../../images/openai.svg';

const DialogueList = ({ dialogues, selectedDialogueId, currentUser, scrollToBottom }) => {
  const findDialogue = ({ _id }) => _id === selectedDialogueId;
  const currentDialogue = dialogues?.find(findDialogue)?.dialogue;
  const getMessageWithContent = ({ content, role }) => content && role !== 'system';
  const withoutSystemMessages = currentDialogue?.filter(getMessageWithContent);

  const dialogue = dialogues?.find(({ _id }) => _id === selectedDialogueId);
  const thinking = dialogue?.thinking;

  const getMessageRow = (message, index, array) => {
    let downloadUrl = null;
    if (message?.document) {
      const byteCharacters = atob(message.document);
      const getCharCodeAtPosition = (_, i) => byteCharacters.charCodeAt(i);
      const byteNumbers = new Array(byteCharacters.length).fill(null).map(getCharCodeAtPosition);
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      downloadUrl = window.URL.createObjectURL(blob);
    }
    return (
      <Message
        key={index}
        message={message}
        downloadUrl={downloadUrl}
        currentUser={currentUser}
        index={index}
        array={array}
        scrollToBottom={scrollToBottom}
        selectedDialogueId={selectedDialogueId}
      />
    );
  };

  const messages = withoutSystemMessages?.map(getMessageRow);
  return (
    <Col className="dialogue">
      {messages}
      {thinking && (
        <Row key={Date.now()} className="message assistant">
          <Row className="header">
            <Row className="avatar">
              <img src={openai} alt="openai" />
            </Row>
            <Row className="label">
              <Row>ChatGPT</Row>
            </Row>
          </Row>
          <Row className="content">
            <span className="typing-indicator"></span>
          </Row>
        </Row>
      )}
    </Col>
  );
};

export default DialogueList;
