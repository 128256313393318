import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Typography, Dropdown, Collapse, Switch } from 'antd';
import { EditOutlined, EllipsisOutlined, LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import { Box } from 'common_components';
import { ROLES, CHAT_TYPES } from 'common_constants/business';
import { NEW_CONTRACT, PAYMENT_MANAGEMENT, CONTRACT_PAYROLL_PERCENTS } from 'common_constants/modals';

import { ArchiveInfo, BlockManagement, ClientInfoRow, ContractInfoTextPart, MonthlySMSInfo } from './ContractInfoPartials';
import SearchSelectUsersMultiple from '../../../components/SearchSelect/SearchSelectUsersMultiple';
import { setCurrentClient, setModal, editContract } from '../../../store/commonReducer';
import { setMainLoader } from '../../../store/uiReducer';
import { error, request, success, warn } from '../../../tools';
import TrafficLight from './TrafficLight';
import ContractCallsInfo from './ContractCallsInfo';
import ButtonsMenu from './ButtonsMenu';

import './styles.scss';

const { Panel } = Collapse;
const { Text, Title } = Typography;

const ContractInfo = ({ data, withInsurance }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const { role, _id } = useSelector((state) => state.common.userAuth);

  const inspector = ROLES[role] <= ROLES.inspector;
  const deputyManager = ROLES[role] <= ROLES.deputyManager;
  const deputyManagerOnly = ROLES[role] === ROLES.deputyManager;
  const managerOnly = ROLES[role] === ROLES.manager;
  const blockManager = ROLES[role] <= ROLES.blockManager;
  const blockManagerOnly = ROLES[role] === ROLES.blockManager;

  const [correspondence, setCorrespondence] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [poorDataFilling, setPoorDataFilling] = useState(data?.poorDataFilling?.mark ?? false);

  const isNotFired = (user) => user?.fired !== true;
  const filterFil = (user) => Number(user.fil) === Number(data.f);
  const isUserAssigned = (user) => data?.access && data.access.includes(user._id);
  const filterUsers = (user) => (isNotFired(user) && filterFil(user)) || isUserAssigned(user);
  const preparedUsers = Object.values(users).filter(filterUsers);

  const onEdit = () => {
    dispatch(setCurrentClient(data.client[0]));
    dispatch(setModal({ name: NEW_CONTRACT, data, fromContracts: true }));
  };

  const handleChangeAccess = async (value) => {
    const validation = (_value) => {
      if (!Array.isArray(_value)) {
        warn('', 'Для призначення, потрібно вказати адвоката.', { value: 'not array' });
        return false;
      }
      if ((managerOnly || deputyManagerOnly) && data.f === undefined) {
        warn('', "Щоб призначити людину, треба спочатку щоб контракт був прив'язаний за філією.", { f: 'undefined' });
        return false;
      }
      return true;
    };

    if (!validation(value)) return;

    dispatch(setMainLoader(true));

    const isAddedLawyer = !data.access || data.access?.length < value.length;

    await request.post(
      '/contracts/setRabfilAccess',
      { contractId: data._id, access: value },
      () => {
        dispatch(editContract({ _id: data._id, access: value }));
        success('', isAddedLawyer ? 'Було призначено помічнику цей договір' : '');
      },
      error,
    );

    if (isAddedLawyer) {
      const findUser = (user) => user._id === value[value.length - 1];
      const assignedUser = preparedUsers.find(findUser);

      const messageData = {
        chatId: data._id,
        sender: assignedUser._id,
        message: `Доброго дня, мене звуть ${assignedUser?.name} ${
          assignedUser?.userPersonalData?.workPhone ? `, мій номер телефону ${assignedUser.userPersonalData.workPhone}` : ''
        }. Мене додано юристом по вашій справі.
        Залюбки допоможу вирішити Ваші питання!`,
        automated: true,
        type: CHAT_TYPES.chatWithClient.key,
      };

      request.post('/chatPrivate/chatSendMessage', messageData, () => success('Клієнту було надіслано повідомлення про ваше призначення'), error);
    }

    dispatch(setMainLoader(false));
  };

  const getCorrespondence = (contract) => {
    setMainLoader(true);
    request.post(
      '/correspondence',
      { _id: contract._id, i: contract.i },
      (res) => {
        setCorrespondence(res.cor);
        setMainLoader(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (data._id) {
      getCorrespondence(data);
    }
  }, [data]);

  const handlePoorDataFilling = (e) => {
    request.post(
      '/contracts/changePoorDataFilling',
      { poorDataFilling: e, contractId: data._id },
      () => {
        setPoorDataFilling(e);
      },
      error,
    );
  };

  const canEditPoorDataFilling = () => {
    if (data?.poorDataFilling?.userId === undefined) {
      return false;
    }

    return !(data.poorDataFilling.userId === _id);
  };

  if (!data) return 'Відсутня інформація';

  return (
    <Card
      className="contractInfo"
      actions={[
        <Button
          className="contractInfo__button"
          style={{ color: '#0958d9' }}
          key="paymentManagement"
          icon={<LineChartOutlined />}
          onClick={() => dispatch(setModal({ name: PAYMENT_MANAGEMENT, data: { contract: data } }))}
        >
          Менеджмент платежів
        </Button>,
        deputyManager && (
          <Button
            className="contractInfo__button"
            style={{ color: '#0958d9' }}
            key="payrollPercents"
            onClick={() => dispatch(setModal({ name: CONTRACT_PAYROLL_PERCENTS, data: { contract: data } }))}
            icon={<BarChartOutlined />}
          >
            Менеджмент відсотків
          </Button>
        ),
        <Dropdown
          key="0"
          className="contractInfo__button"
          overlay={<ButtonsMenu data={data} withInsurance={withInsurance} correspondence={correspondence} />}
          trigger={['click']}
          style={{ color: '#0958d9' }}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>,
      ]}
    >
      <Row gutter={16} style={{ marginBottom: '10px' }}>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={18}>
          <ClientInfoRow data={data} />
          <br />
        </Col>
        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="assignment-item-buttons-inner">
            <Row className="assignment-item-buttons-inner">
              <Col className="assignment-item-buttons">
                {inspector && (
                  <Button type="primary" className="assignment-item__button expanded" style={{ backgroundColor: 'orange' }} onClick={onEdit}>
                    <EditOutlined />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Collapse activeKey={activeKey} accordion onChange={(key) => setActiveKey((prevKey) => (prevKey.includes(key) ? [] : [key]))}>
            <Panel header="Інформація про контракт" key="1">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ContractInfoTextPart data={data} users={users} />
                {data.ad && <ArchiveInfo data={data} />}
                {!data.ad && data.mp && <MonthlySMSInfo data={data} breakMPayment={data?.mb} />}
                <div className="switch__data-incorrect">
                  <Switch checked={poorDataFilling} disabled={canEditPoorDataFilling()} style={{ marginRight: 8 }} onChange={handlePoorDataFilling} />{' '}
                  Дані заповнені погано
                </div>
              </div>
            </Panel>
            <Panel header="Дзвінки по договору" key="2">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ContractCallsInfo contractId={data._id} />
              </div>
            </Panel>
          </Collapse>
          <TrafficLight data={data} correspondence={correspondence} />
        </Col>
      </Row>
      {blockManager && <BlockManagement data={data} />}
      {deputyManager && !blockManagerOnly && (
        <Card>
          {inspector && (
            <Title level={2} style={{ margin: 0 }}>
              Філія
            </Title>
          )}
          <Text strong>Призначити людей:</Text>
          <SearchSelectUsersMultiple
            disabled={(managerOnly || deputyManagerOnly) && data.f === undefined}
            onChange={handleChangeAccess}
            users={preparedUsers}
            value={data.access}
            style={{ width: '100%' }}
          />
          {(managerOnly || deputyManagerOnly) && data.f === undefined && (
            <Box>
              <Text type="warning" className="sender-filter-box-text">
                Щоб призначити людину, треба спочатку щоб контракт був прив'язаний за філією.
              </Text>
            </Box>
          )}
        </Card>
      )}
    </Card>
  );
};

export default ContractInfo;
