import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, DatePicker, Button, InputNumber, Tag, AutoComplete } from 'antd';
import { editSugar } from '../../store/commonReducer';
import { error, request, success, warn } from '../../tools';
import { PAY_PURPOSES, PAY_TYPES, ROLES, COUNTRY_PHONE_CODES } from 'common_constants/business';
import SearchSelectPayTypeSugar from '../../components/SearchSelect/SearchSelectPayTypeSugar';
import SearchSelectPayPurp from '../../components/SearchSelect/SearchSelectPayPurp';
import SelectAssignment from '../../components/SelectAssignment/SelectAssignment';
import SearchSelectHotlines from '../../components/SearchSelect/SearchSelectHotlines';
import SearchSelect from '../../components/SearchSelect';
import CountryPhCode from '../../components/CountryPhCode';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import './styles.scss';

const EditSugar = ({ open, onCancel, data, disabledForBalance }) => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const FILII = useSelector((state) => state.common.FILII);

  const [form] = Form.useForm();
  const timeoutRef = useRef(null);

  const [hotlineDate, setHotlineDate] = useState(data?.hotlineDate ? data?.hotlineDate : null);
  const [formData, setFormData] = useState({ payBy: null });
  const [loading, setLoading] = useState(false);
  const [payday, setPayday] = useState(data?.payday ? dayjs(data?.payday) : null);
  const [localAssignmentsList, setLocalAssignmentsList] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [maxOptionWidth, setMaxOptionWidth] = useState(0);
  const [selectedFil, setSelectedFil] = useState(null);

  const dateFormat = 'DD.MM.YYYY';
  const getPayday = (value) => {
    if (value) {
      setPayday(value);
    } else {
      setPayday(null);
    }
  };

  const handleHotlineDateChange = (value) => {
    if (value) {
      setHotlineDate(value);
    } else {
      setHotlineDate(null);
    }
  };

  const initialValues = {
    contractIndex: data?.contractIndex,
    name: data?.name,
    payday: dayjs(data?.payday),
    payfor: PAY_PURPOSES?.[data?.payfor]?.value,
    payforRest: data?.payforRest,
    payBy: PAY_TYPES[data?.payBy]?.value,
    payByOther: data?.payByOther,
    amount: data?.amount,
    currency: data?.currency,
    payrollToFil: data?.payrollToFil,
    hotlineId: data?.hotlineId,
    assignment: data?.assignment,
    hotlineDate: data?.hotlineDate ? dayjs(data.hotlineDate) : null,
    ph: data?.ph,
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        contractIndex: data.contractIndex,
        name: data.name,
        payday: dayjs(data.payday),
        payfor: PAY_PURPOSES?.[data?.payfor]?.value,
        payforRest: data.payforRest,
        payBy: PAY_TYPES[data.payBy]?.value,
        payByOther: data.payByOther,
        payrollToFil: data?.payrollToFil,
        amount: data.amount,
        currency: data.currency,
        assignmentDate: null,
        hotlineDate: dayjs(data?.hotlineDate),
        hotlineId: data?.hotlineId,
        ph: data?.ph,
        countryPhCode: data?.countryPhCode,
      });
      setHotlineDate(dayjs(data?.hotlineDate));
      setFormData({
        contractIndex: data.contractIndex,
        name: data.name,
        payday: dayjs(data.payday),
        payBy: PAY_TYPES[data.payBy]?.value,
        payforRest: data.payforRest,
        payfor: PAY_PURPOSES?.[data?.payfor]?.value,
        payByOther: data.payByOther,
        payrollToFil: data?.payrollToFil,
        amount: data.amount,
        currency: data.currency,
        assignment: data?.assignment,
        hotlineId: data?.hotlineId,
        hotlineDate: dayjs(data?.hotlineDate),
        ph: data?.ph,
        countryPhCode: data?.countryPhCode,
      });
    }
  }, [data, form]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const sugarData = {
      contractIndex: form.getFieldValue('contractIndex') !== undefined ? form.getFieldValue('contractIndex') : null || data?.contractIndex,
      payday: form.getFieldValue('payday') || data?.payday,
      name: form.getFieldValue('name') === '' ? null : form.getFieldValue('name') || data?.name,
      payfor: (form.getFieldValue('payfor') && form.getFieldValue('payfor').value) || form.getFieldValue('payfor'),
      payforRest: form.getFieldValue('payforRest'),
      payBy: form.getFieldValue('payBy') || data?.payBy,
      payByOther: form.getFieldValue('payByOther') || data?.payByOther,
      amount: form.getFieldValue('amount'),
      currency: data?.currency,
      hotlineDate: form.getFieldValue('hotlineDate') ? form.getFieldValue('hotlineDate').toISOString() : null,
      ph: form.getFieldValue('ph') ? form.getFieldValue('ph') : null,
    };

    if (!inspector) {
      sugarData.amount = form.getFieldValue('amount');
    } else if (sugarData.payfor === PAY_PURPOSES[5].value) {
      sugarData.hotlineId = form.getFieldValue('hotlineId');
      sugarData.payrollToFil = form.getFieldValue('payrollToFil') === 0 ? 0 : form.getFieldValue('payrollToFil') || data?.payrollToFil;
    }

    if (sugarData.payfor === PAY_PURPOSES[4].value) {
      sugarData.assignment = {
        assignmentId: formData.assignmentId,
        assignmentIndex: formData.assignmentIndex,
      };
      sugarData.isFromAssignmentReport = true;
      // sugarData.assignmentStatus = '1';
      // sugarData.paymentStatus = '1';
    } else {
      sugarData.assignment = null;
      sugarData.isFromAssignmentReport = false;
    }
    if (sugarData.payfor === PAY_PURPOSES[8].value || sugarData.payfor === PAY_PURPOSES[9].value) {
      sugarData.ph = form.getFieldValue('ph');
      sugarData.countryPhCode = formData?.countryPhCode;
    }

    await request.post(
      `/sugar/editSugar/${data?._id}`,
      sugarData,
      ({ sugar }) => {
        dispatch(
          editSugar({
            id: data?._id,
            updatedData: sugar,
            historyData: {
              transactionType: 'edit',
              additionalInfo: '...',
            },
          }),
        );

        success('', 'Транзакція була успішно відредагована.');
        setLoading(false);
        onCancel();
      },
      () => {
        error('', 'Помилка при редагуванні.');
        setLoading(false);
      },
    );
  };

  const onContractFiled = (contractIndex) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (!contractIndex) return;

      request.post(
        '/contracts/getClientByIndex',
        { i: contractIndex },
        (res) => {
          Modal.confirm({
            content: res.data.client ? "Використати ім'я: " + res.data.client + '?' : "Зверніть увагу, клієнт не прив'язаний до договору!",
            onOk() {
              if (!res.data) return;
              form.setFieldsValue({ name: res.data.client, ph: res.data.ph });
              setFormData({
                ...formData,
                contractIndex,
                ph: res.data.ph,
              });
            },
            onCancel() {},
          });
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 1000);
  };

  const onNameFiled = (value) => {
    clearTimeout(timeoutRef.current);
    setNameSuggestions([]);

    timeoutRef.current = setTimeout(() => {
      if (value.length < 5) return;

      request.post(
        '/sugar/getContractByName',
        { n: value },
        (res) => {
          if (res.data && res.data.length > 0) {
            setNameSuggestions(
              res.data
                .sort((a, b) => (a.ad ? 1 : -1))
                .map((contract) => ({
                  value: (
                    <span>
                      {contract.name} - {FILII[contract.fil]} - {contract.i} {contract.ad && <Tag color="gold">A</Tag>}
                    </span>
                  ),
                  contract: contract,
                })),
            );
          } else {
            warn(' ', "Зверніть увагу, клієнт не прив'язаний до договору!");
            setNameSuggestions([]);
          }
        },
        (_, __, axiosError) => {
          error(axiosError.response.data.error, ' ');
        },
      );
    }, 800);
  };

  const handleContractSelection = (selectedContract) => {
    form.setFieldsValue({
      contractIndex: selectedContract.i,
      name: selectedContract.name,
      ph: selectedContract.ph,
    });
    setFormData({ ...formData, contractIndex: selectedContract.i, name: selectedContract.name, ph: selectedContract.ph });
  };

  const handleSelectName = (value, option, index) => {
    const selectedContract = option.contract;
    handleContractSelection(selectedContract, index);
  };

  const handlePayByChange = (value) => {
    setFormData({
      ...formData,
      payBy: value,
    });
  };

  const handleHotlineIdChange = (value) => {
    form.setFieldValue('hotlineId', value);
  };

  const handlePayForChange = (value) => {
    if (value === 8 || value === 9) {
      setFormData({
        ...formData,
        payfor: value,
        countryPhCode: COUNTRY_PHONE_CODES.UA,
      });
      return;
    }
    setFormData({
      ...formData,
      payfor: value,
    });
  };

  useEffect(() => {
    if (open && formData.payfor === PAY_PURPOSES[4].value && formData?.assignment?.assignmentId !== undefined) {
      request.post('/assignment/getById', { id: formData?.assignment?.assignmentId, contractIndex: formData.contractIndex }, ({ assignment }) => {
        setFormData({ ...formData, assignmentDate: dayjs(assignment.date) });
        form.setFieldsValue({ assignmentDate: dayjs(assignment.date) });
        setLocalAssignmentsList([assignment]);
      });
    }
  }, [data?.assignment?.assignmentIndex]);
  useEffect(() => {
    if (formData.contractIndex && formData.payfor === PAY_PURPOSES[4].value && formData.assignmentDate) {
      setLoading(true);
      request.post(
        '/assignment/getForSugar',
        { targetDate: formData.assignmentDate, contractNumberFilter: formData.contractIndex },
        ({ assignments }) => {
          setLocalAssignmentsList(assignments);
          setLoading(false);
        },
      );
    }
  }, [formData.contractIndex, formData.payfor, formData.assignmentDate]);

  const handleAssignmentDateChange = (value) => {
    setFormData({
      ...formData,
      assignmentDate: value,
    });
  };

  useEffect(() => {
    const lengths = [];
    nameSuggestions.forEach((suggestion) => {
      let suggestionLength = {
        nameLength: suggestion.contract.name.length || 0,
        filLength: FILII[suggestion.contract.fil]?.length || 0,
      };
      lengths.push(suggestionLength.filLength + suggestionLength.nameLength);
    });

    const maxLength = Math.max(...lengths);

    if (maxLength >= 50) {
      setMaxOptionWidth(maxLength * 10);
    } else {
      setMaxOptionWidth(maxLength * 10 + 40);
    }
  }, [nameSuggestions]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  const handlePayrollToFilChange = (value) => {
    setSelectedFil(value);
    form.setFieldsValue({ payrollToFil: value });
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const onlyNumbers = input.replace(/\D/g, '').slice(0, 10);

    setFormData({
      ...formData,
      ph: onlyNumbers ? onlyNumbers : null,
    });
  };
  const handleCountryPhCodehange = (value) => {
    setFormData({
      ...formData,
      countryPhCode: value,
    });
  };

  useEffect(() => {
    if (formData.payfor === PAY_PURPOSES[5].value && hotlineDate) {
      form.setFieldValue('hotlineId', formData.hotlineId);
    }
  }, [formData.payfor, hotlineDate, formData.hotlineId, form]);

  return (
    <Modal title={disabledForBalance ? 'Інформація про транзакцію' : 'Редагувати транзакцію'} open={open} onCancel={(e) => onCancel(e)} footer={null}>
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleFormSubmit}
          initialValues={{ fil: inspector ? '' : userAuth.fil, ...initialValues }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
        >
          <Form.Item
            label="№ Договору"
            name="contractIndex"
            rules={[
              {
                required:
                  form.getFieldValue('payfor') &&
                  form.getFieldValue('payfor') !== 5 &&
                  form.getFieldValue('payfor') !== 8 &&
                  form.getFieldValue('payfor') !== 9 &&
                  form.getFieldValue('payfor') !== 50,
                message: "Обов'язково для заповнення",
              },
            ]}
          >
            <InputNumber
              disabled={data?.payfor === String(PAY_PURPOSES[4].value) || formData.payfor === PAY_PURPOSES[3].value}
              min={1}
              onChange={onContractFiled}
            />
          </Form.Item>

          <Form.Item
            label="ПІБ"
            name="name"
            rules={[
              {
                required: form.getFieldValue('payfor') && form.getFieldValue('payfor') !== 50,
                message: 'Будь ласка введіть прізвище клієнта!',
              },
              { min: 5, message: 'Мінімум 5 символів!' },
            ]}
          >
            <AutoComplete
              options={nameSuggestions.map((suggestion) => ({
                label: suggestion.value,
                value: suggestion.value,
                contract: suggestion.contract,
              }))}
              onSelect={handleSelectName}
              onSearch={onNameFiled}
              placeholder="Пошук за іменем"
              dropdownMatchSelectWidth={window.screen.width < 768 ? 350 : maxOptionWidth}
              allowClear
            />
          </Form.Item>

          <Form.Item label="Дата оплати" name="payday" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <DatePicker onChange={(e) => getPayday(e)} locale={locale} value={payday} format={dateFormat} />
          </Form.Item>

          <Form.Item label="Призначення" name="payfor" rules={[{ required: true, message: 'Будь ласка введіть призначення платежу!' }]}>
            <SearchSelectPayPurp
              disabledState={formData.payfor === String(PAY_PURPOSES[4].value) || formData.payfor === PAY_PURPOSES[3].value}
              value={formData.payfor}
              onChange={handlePayForChange}
            />
          </Form.Item>

          {formData.payfor === PAY_PURPOSES[4].value && (
            <Form.Item
              label="Дата виходу"
              name="assignmentDate"
              rules={[{ required: inspector ? false : true, message: 'Будь ласка введіть дату виходу!' }]}
            >
              <DatePicker
                locale={locale}
                value={formData.assignmentDate}
                format={dateFormat}
                disabled={data?.payfor === String(PAY_PURPOSES[4].value)}
                onChange={handleAssignmentDateChange}
              />
            </Form.Item>
          )}

          {formData.payfor === PAY_PURPOSES[4].value && (
            <Form.Item
              className="sugar-assignment"
              label="Вихід"
              name="assignmentId"
              rules={[{ required: inspector ? false : true, message: 'Будь ласка оберіть вихід' }]}
              style={{ height: '230px', padding: '0' }}
            >
              <SelectAssignment
                disabled={data?.payfor === String(PAY_PURPOSES[4].value)}
                form={form}
                formData={formData}
                setFormData={setFormData}
                assignmentsList={localAssignmentsList}
              />
            </Form.Item>
          )}

          {formData.payfor === PAY_PURPOSES[3].value && (
            <Form.Item
              label="Залишок"
              name="payforRest"
              rules={[{ required: formData.payfor === PAY_PURPOSES[3].value, message: 'Будь ласка введіть призначення платежу!' }]}
            >
              <InputNumber onKeyDown={handleKeyPress} />
            </Form.Item>
          )}

          {inspector && formData.payfor === PAY_PURPOSES[5].value && (
            <Form.Item label="Оберіть філію" name="payrollToFil">
              <SearchSelect
                placeholder={'Консультація на філії'}
                list={FILII}
                value={formData.payrollToFil}
                valueName="value"
                labelName="label"
                onChange={handlePayrollToFilChange}
              />
            </Form.Item>
          )}

          {formData.payfor !== PAY_PURPOSES[51].value && (
            <p>
              <span style={{ color: 'red' }}>*</span> Після створення звіту буде проведено нарахування грошей на баланс клієнта, а потім списання цієї
              суми
            </p>
          )}

          {formData.payfor === PAY_PURPOSES[5].value && (
            <Form.Item label="Дата консультації" name="hotlineDate">
              <DatePicker format={dateFormat} locale={locale} onChange={handleHotlineDateChange} value={hotlineDate} allowClear />
            </Form.Item>
          )}

          {inspector && formData.payfor === PAY_PURPOSES[5].value && formData.hotlineDate && (
            <Form.Item label="Консультація" name="hotlineId">
              <SearchSelectHotlines
                fil={userAuth.fil}
                selectedFil={selectedFil}
                date={hotlineDate}
                onChange={handleHotlineIdChange}
                value={formData.hotlineId}
              />
            </Form.Item>
          )}
          {(formData.payfor === PAY_PURPOSES[8].value || formData.payfor === PAY_PURPOSES[9].value) && (
            <Form.Item
              name="ph"
              label="Телефон"
              rules={[
                {
                  required: form.getFieldValue('payfor') === 8 || form.getFieldValue('payfor') === 9,
                  message: "Обов'язково для заповнення",
                },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <CountryPhCode
                  value={formData?.countryPhCode ? formData?.countryPhCode : COUNTRY_PHONE_CODES.UA}
                  onChange={handleCountryPhCodehange}
                />

                <Input
                  aria-label="Телефон"
                  value={formData?.ph ? formData?.ph : ''}
                  onChange={(e) => handlePhoneChange(e)}
                  maxLength={10}
                  placeholder="Введіть номер телефону"
                />
              </div>
            </Form.Item>
          )}

          <Form.Item label="Спосіб оплати" name="payBy" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <SearchSelectPayTypeSugar value={formData.payBy} onChange={handlePayByChange} isInspector={inspector} />
          </Form.Item>

          {formData.payBy === PAY_TYPES.other.value && (
            <Form.Item label="Куди платив уточнення" name="payByOther" rules={[{ required: true, message: 'Будь ласка вкажіть уточнення!' }]}>
              <Input />
            </Form.Item>
          )}

          <Form.Item label="Сума" name="amount" rules={[{ required: true, message: "Обов'язково для заповнення" }]}>
            <InputNumber onWheel={(e) => e.preventDefault()} onKeyDown={handleKeyPress} min={0} disabled={!inspector} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button type="primary" htmlType="submit" disabled={disabledForBalance}>
              Зберегти
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditSugar;
