import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Badge, Menu } from 'antd';
import {
  CarryOutOutlined,
  MoneyCollectOutlined,
  TabletFilled,
  FileAddOutlined,
  PlusCircleOutlined,
  FieldTimeOutlined,
  MailOutlined,
  ReadOutlined,
  InteractionOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { red } from '@ant-design/colors';

import {
  INCOMES,
  ASSIGNMENTS_INFO_LIST,
  BUTTON_CONTAINER,
  CONTRACT_TASKS_CRM,
  CONTRACT_LIST_FILES,
  CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
  LIBRARY_MINI_LIST,
  HARVEST_INFO_LIST,
  SEND_CONTRACT_DATA_MFO,
  DOCUMENT_TEMPLATES,
} from 'common_constants/modals';

import { InsurancePart, VotingButton } from './ContractInfoPartials';
import { setModal } from '../../../store/commonReducer';
import ChatWithAccountantButton from './ContractInfoPartials/ChatWithAccountantButton';

const ButtonsMenu = ({ data, withInsurance, correspondence }) => {
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [insuranceTransactionState, setInsuranceTransactionState] = useState(false);
  const [insuranceTransactionHistoryState, setInsuranceTransactionHistoryState] = useState(false);
  const [isGenerateContractLoading, setIsGenerateContractLoading] = useState(false);

  const [insurances, setInsurances] = useState(data.insurance);

  const onGenerateDocument = () => {
    setIsGenerateContractLoading(true);

    const requestData = {
      contractId: data._id,
      clientId: data?.client[0]._id,
    };

    dispatch(setModal({ name: DOCUMENT_TEMPLATES, data: requestData }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Menu style={{ maxWidth: isMobile ? '100%' : '70%', marginLeft: 'auto' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Menu.Item key="voting">
          <VotingButton contract={data} correspondence={correspondence} />
        </Menu.Item>

        <Menu.Item key="accountant">
          <ChatWithAccountantButton data={data} />
        </Menu.Item>
        <Menu.Item key="chat">
          <Button
            type="primary"
            onClick={() => dispatch(setModal({ name: BUTTON_CONTAINER, data: { data: data, title: 'Виберіть чат', chat: true } }))}
          >
            <TabletFilled /> Чат &nbsp;
          </Button>
        </Menu.Item>

        {withInsurance && (
          <Menu.Item key="insurance">
            <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setInsuranceTransactionState(true)}>
              Додати страхову транзакцію
            </Button>
          </Menu.Item>
        )}

        {withInsurance && (
          <Menu.Item key="insurance">
            <Button type="primary" icon={<FieldTimeOutlined />} onClick={() => setInsuranceTransactionHistoryState(true)}>
              Історія страхових транзакцій
            </Button>
          </Menu.Item>
        )}

        {withInsurance && (
          <InsurancePart
            contractId={data._id}
            clientId={data.C}
            insurances={insurances}
            handleChangeInsurances={(value) => setInsurances(value)}
            insuranceTransactionState={insuranceTransactionState}
            handleChangeInsuranceTransactionState={() => setInsuranceTransactionState(!insuranceTransactionState)}
            insuranceTransactionHistoryState={insuranceTransactionHistoryState}
            handleChangeInsuranceTransactionHistoryState={() => setInsuranceTransactionHistoryState(!insuranceTransactionHistoryState)}
          />
        )}

        <Menu.Item key="assignments" onClick={() => dispatch(setModal({ name: ASSIGNMENTS_INFO_LIST, data: { contract: data } }))}>
          <Button type="primary" icon={<BankOutlined />}>
            Виходи
          </Button>
        </Menu.Item>

        <Menu.Item key="tasks" onClick={() => dispatch(setModal({ name: CONTRACT_TASKS_CRM, data: { contract: data } }))}>
          <Button type="primary" icon={<CarryOutOutlined />}>
            Завдання &nbsp;
            {data.uncompletedTasks > 0 && <Badge color={red.primary} count={data.uncompletedTasks} style={{ marginBottom: '2.5px' }} />}
          </Button>
        </Menu.Item>

        <Menu.Item key="files" onClick={() => dispatch(setModal({ name: CONTRACT_LIST_FILES, data: { _id: data._id, i: data.i } }))}>
          <Button type="primary" icon={<FileAddOutlined />}>
            Додати файл
          </Button>
        </Menu.Item>

        <Menu.Item key="incomes" onClick={() => dispatch(setModal({ name: INCOMES, data: { contractForm: data, self: true } }))}>
          <Button type="primary" icon={<MoneyCollectOutlined />}>
            Доходи
          </Button>
        </Menu.Item>

        <Menu.Item key="library" onClick={() => dispatch(setModal({ name: LIBRARY_MINI_LIST, data: { shoo: data.prc } }))}>
          <Button icon={<ReadOutlined />} type="primary">
            Міні бібліотека
          </Button>
        </Menu.Item>

        <Menu.Item
          key="correspondence"
          onClick={() =>
            dispatch(
              setModal({
                name: CORRESPONDENCE_MODAL_FOR_THE_CONTRACT,
                data: {
                  _id: data._id,
                  i: data.i,
                  f: data.f,
                },
              }),
            )
          }
        >
          <Button icon={<MailOutlined />} type="primary">
            Кореспонденція
          </Button>
        </Menu.Item>

        <Menu.Item key="harvest" onClick={() => dispatch(setModal({ name: HARVEST_INFO_LIST, data: { contractNumber: data.i } }))}>
          <Button icon={<MoneyCollectOutlined />} type="primary">
            Жнива
          </Button>
        </Menu.Item>

        <Menu.Item key="gendoc" onClick={onGenerateDocument} loading={isGenerateContractLoading}>
          <Button icon={<FileAddOutlined />} type="primary">
            Генерувати документ
          </Button>
        </Menu.Item>

        <Menu.Item
          key="mfo"
          onClick={() => dispatch(setModal({ name: SEND_CONTRACT_DATA_MFO, data: { contractId: data?._id } }))}
          loading={isGenerateContractLoading}
        >
          <Button icon={<InteractionOutlined />} type="primary">
            Вибрати МФО
          </Button>
        </Menu.Item>
      </div>
    </Menu>
  );
};

export default ButtonsMenu;
