import React, { useRef } from 'react';
import MessagesList from './MessagesList';

const MessagesWrapperForModal = ({ messages = [], setMessagesLoader, onGetFile, prevMessagesCount = 0, listRef }) => {
  const cachedImages = useRef();
  const rowCount = messages?.length;

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    onGetFile({
      fileId,
      fileName,
      callback: () => {
        setMessagesLoader(false);
      },
    });
  };

  return (
    <div className="messages-wrapper" style={{ width: '100%', height: '100%', display: 'flex', flexGrow: 1, minHeight: '400px' }}>
      <MessagesList
        height={400}
        width={'100%'}
        messages={messages}
        listRef={listRef}
        rowCount={rowCount}
        prevMessagesCount={prevMessagesCount}
        handleDownloadFile={handleDownloadFile}
        cachedImages={cachedImages}
      />
    </div>
  );
};

export default MessagesWrapperForModal;
