import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Popover, Checkbox, message, Popconfirm, Alert, Row, Col, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { CURRENCIES, PAY_PURPOSES, PAY_TYPES, FONDY_PAY_TYPE } from 'common_constants/business/';
import EditSugar from '../../Modals/AddSugar/EditSugar';
import SugarHistory from './SugarHistory';
import HoverableUser from '../../components/HoverableUser/HoverableUser';
import Link from 'antd/es/typography/Link';
import { setModal, setSugarApprovedStatus } from '../../store/commonReducer';
import { CLIENT_INFO } from 'common_constants/modals';
import { request } from '../../tools';
import { ROLES } from 'common_constants/business';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';

import './Sugar.scss';

const { Text } = Typography;

const SugarInfo = ({ data }) => {
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);
  const FILII = useSelector((state) => state.common.FILII);

  const adminOnly = ROLES[userAuth.role] === ROLES.admin;
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const manager = ROLES[userAuth.role] >= ROLES.manager;

  const [loadApproved, setLoadApproved] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [selectedEditObject, setSelectedEditObject] = useState(null);

  const payforIsOther = data?.payfor === 'other';

  const payrollElementWidth = !payforIsOther ? 241 : 300;

  const openClient = () => {
    dispatch(setModal({ name: CLIENT_INFO, data: data.client, fromContracts: true, interactionsBlocked: true }));
  };

  const showEditModal = (objectToEdit) => {
    const daysSinceCreation = dayjs().diff(dayjs(objectToEdit.createdAt), 'day');

    if (manager && daysSinceCreation > 40) {
      message.warning('Редагування заблоковано через 40 днів після створення.');
      return;
    }

    if (manager && userAuth._id !== objectToEdit.author) {
      message.warning('Ви не можете редагувати цей запис, оскільки він не є вашим.');
      return;
    }

    setEditModalVisible(true);
    setSelectedEditObject(objectToEdit);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
    setSelectedEditObject(null);
  };

  const showHistoryModal = () => {
    setHistoryModalVisible(true);
  };

  const hideHistoryModal = () => {
    setHistoryModalVisible(false);
  };

  const onChangeApproved = (e) => {
    if (!inspector) {
      message.warning('У вас немає прав на зміну статусу перевірки.');
      return;
    }
    if (data.approved?.status && !adminOnly) {
      message.warning('Зняти статус перевірки може тільки Адмін.');
      return;
    }
    setLoadApproved(true);
    const route = '/sugar/setApproved';
    const body = { sugarId: data._id, status: !data.approved?.status };
    const onSuccess = (res) => {
      const payload = { sugarId: data._id, approved: res.approved };
      dispatch(setSugarApprovedStatus(payload));
      setLoadApproved(false);
      message.success('Статус перевірки змінено.');
    };
    const onError = (err) => {
      setLoadApproved(false);
      message.error('Не вдалось змінити статус перевірки.');
    };
    request.post(route, body, onSuccess, onError);
  };

  const canEditAndViewHistory = adminOnly || data.payBy !== '51' || data.payBy !== FONDY_PAY_TYPE;
  const fondy = data.payBy !== '51' && data.payBy !== FONDY_PAY_TYPE;

  const author = data?.author;
  const usersAmount = data?.payroll?.[0]?.usersAmount;

  const isAuthorInUsersAmount = usersAmount?.some((userAmount) => userAmount.user === author);

  if (!data) {
    return 'Відсутня інформація';
  }

  return (
    <Card
      className="sugarInfo"
      actions={
        canEditAndViewHistory
          ? [
              <Button key="history" type="text" icon={<HistoryOutlined />} onClick={showHistoryModal}>
                Історія змін
              </Button>,

              <Button
                key="edit"
                type="text"
                style={{ color: '#0958d9' }}
                icon={<EditOutlined />}
                onClick={() => showEditModal(data)}
                disabled={data?.approved?.status === true}
              >
                Редагувати
              </Button>,
            ]
          : []
      }
      title={
        <span>
          <Text strong>Перевірено:&nbsp;</Text>
          <Popconfirm
            title={`Сахарок ${data.approved?.status ? '' : 'не'} перевірено`}
            description="Бажаєте змінити статус перевірки?"
            onConfirm={onChangeApproved}
            okText="Так"
            cancelText="Ні"
          >
            <Checkbox checked={data?.approved?.status} disabled={loadApproved}>
              {users[data.approved?.userId]?.p ?? '-'}
            </Checkbox>
          </Popconfirm>
        </span>
      }
    >
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <span>
            <b>Філія:</b> {FILII[data.fil]}
          </span>

          {data.name && (
            <span>
              <b>ПІБ: </b>
              <>
                {data.name} &nbsp;
                {data.client && <Link onClick={openClient}>подробиці</Link>}
              </>
            </span>
          )}

          {data.contractIndex && (
            <span>
              <b>№ договору:</b> {data.contractIndex}
            </span>
          )}

          <span>
            <b>Призначення платежу:</b> {PAY_PURPOSES?.[data?.payfor]?.label}
          </span>
          {(PAY_PURPOSES?.[data?.payfor]?.value === 8 || PAY_PURPOSES?.[data?.payfor]?.value === 9) && (data.countryPhCode || data.ph) && (
            <span>
              <b>Телефон:</b> +{data.countryPhCode} {data.ph}
            </span>
          )}

          <span>
            <b>Спосіб оплати:</b> {PAY_TYPES[data.payBy]?.label} {data.payByOther}
          </span>

          <span>
            <b>Сума: </b>
            {data.amount}
            {data?.currency ? CURRENCIES[data.currency]?.symbol || '₴' : '₴'}
          </span>

          {typeof data.payforRest === 'number' ? (
            <span>
              <b>Залишок:</b> <span style={{ color: 'red' }}>₴{data.payforRest}</span>
            </span>
          ) : null}

          <span>
            <b>Дата оплати:</b> {dayjs(data.payday).format('DD.MM.YYYY HH:mm:ss')}
          </span>

          {data?.payroll?.[0] && (
            <>
              {!isAuthorInUsersAmount && fondy && (
                <React.Fragment>
                  <br />
                  <Alert
                    style={{ width: payrollElementWidth }}
                    message={`${payforIsOther ? 'Обрано категорію Інше, відсоток за договором не нараховано.' : 'Відсоток на чужий договір!'}`}
                    type="warning"
                    showIcon
                  />
                </React.Fragment>
              )}

              <br />

              {data?.payroll[0]?.usersAmount?.map((userAmount, index) => {
                const user = users[userAmount.user];
                return (
                  <React.Fragment key={index}>
                    {!!userAmount.user && (
                      <span>
                        <b>
                          Відсоток{' '}
                          <Popover content={<HoverableUser id={user?._id ?? '-'} />} arrow trigger="hover">
                            <Link>{user?.p ?? '-'}</Link>
                          </Popover>
                          :
                        </b>{' '}
                        <Tag color="blue" style={{ fontWeight: 'bold' }}>
                          {userAmount.percent}%
                        </Tag>
                        - ₴{userAmount.amount}
                      </span>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}

          {typeof data?.payrollToFil === 'number' && (
            <span>
              <b>Консультація на філії:</b> {FILII[data?.payrollToFil]}
            </span>
          )}
        </Col>

        <Col xs={24} sm={12}>
          {data.createdAt && (
            <span>
              <b>Дата створення:</b> {dayjs(data.createdAt).format('DD.MM.YYYY HH:mm:ss')}
            </span>
          )}

          <span>
            <b>№ сахорку:</b> {data.i}
          </span>

          {data.author && (
            <span>
              <b>Автор:</b>{' '}
              <Popover content={<HoverableUser id={users[data.author]?._id ?? '-'} />} arrow trigger="hover">
                <Link>{users[data.author]?.p ?? '-'}</Link>
              </Popover>
            </span>
          )}
        </Col>
      </Row>

      <EditSugar open={editModalVisible} onCancel={hideEditModal} data={selectedEditObject} />

      <SugarHistory open={historyModalVisible} onCancel={hideHistoryModal} origin={data} data={data.history} users={users} />
    </Card>
  );
};

export default SugarInfo;
