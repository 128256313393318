import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { setDialogues, selectDialogues, addMessage, addDialogue, setTypingEffect, setPlaceholder, setThinking } from '../../store/aiReducer';
import { request, error } from '../../tools/index';
import { Header, Body, Footer } from './Partials';

import './Documents.scss';

const getOption = (dialogue) => ({
  value: dialogue._id,
  label: dialogue.dialogue[1].content,
});

const Documents = () => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const dialogues = useSelector(selectDialogues);
  const [loading, setLoading] = useState(false);
  const [selectedDialogueId, setSelectedDialogueId] = useState(null);
  const socket = useSelector((state) => state.common.socket);

  const dialogue = dialogues?.find(({ _id }) => _id === selectedDialogueId);

  const handleSelectDialogue = useCallback((value) => setSelectedDialogueId(value), [setSelectedDialogueId]);
  const startDialogue = useCallback(
    (query) => {
      setLoading(true);
      dispatch(
        setPlaceholder({
          status: true,
          message: query,
        }),
      );
      socket.emit('start-dialogue', { query });
    },
    [dispatch],
  );
  const continueDialogue = useCallback(
    (query) => {
      setLoading(true);
      dispatch(setThinking({ dialogueId: selectedDialogueId, thinking: true }));
      dispatch(setTypingEffect(true));
      const payload = {
        dialogueId: selectedDialogueId,
        message: { content: query, role: 'user' },
      };
      dispatch(addMessage(payload));
      socket.emit('continue-dialogue', { dialogueId: selectedDialogueId, query });
    },
    [selectedDialogueId, dispatch],
  );
  const handleClick = useCallback(
    (query) => (selectedDialogueId ? continueDialogue(query) : startDialogue(query)),
    [selectedDialogueId, continueDialogue, startDialogue],
  );
  const handlSetNewDialogue = useCallback(() => setSelectedDialogueId(null), [setSelectedDialogueId]);
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  }, [messagesEndRef]);

  useEffect(() => {
    const route = '/documents/getDialogues';
    const onSuccess = ({ dialogues }) => dispatch(setDialogues(dialogues));
    const onError = (_, __, axiosError) => {
      error('Помилка!', axiosError.data);
      setLoading(false);
    };
    request.post(route, {}, onSuccess, onError);
  }, []);
  useEffect(() => scrollToBottom(), [dialogues, selectedDialogueId]);
  useEffect(() => {
    if (!socket) return;
    const handleStartDialogue = (data) => {
      dispatch(setTypingEffect(true));
      setSelectedDialogueId(data._id);
      dispatch(addDialogue(data));
      setLoading(false);
      dispatch(
        setPlaceholder({
          status: false,
          message: '',
        }),
      );
    };
    const handleContinueDialogue = (data) => {
      const { dialogueId, message, keepLoading } = data;
      dispatch(addMessage({ dialogueId, message }));
      if (!keepLoading) {
        dispatch(setThinking({ dialogueId: dialogueId, thinking: false }));
        setLoading(false);
      }
    };

    const handleErrorDialogue = (data) => {
      error('Помилка!', 'Помилка від сервера');
      const { dialogueId } = data;
      dispatch(
        addMessage({
          dialogueId,
          message: {
            role: 'assistant',
            content: 'Вибачте, сталася помилка. Спробуйте ще раз',
          },
        }),
      );
      dispatch(setThinking({ dialogueId, thinking: false }));
      setLoading(false);
    };

    socket.on('start-dialogue', handleStartDialogue);
    socket.on('continue-dialogue', handleContinueDialogue);
    socket.on('dialogue-error', handleErrorDialogue);

    return () => {
      socket.off('dialogue-error', handleErrorDialogue);
      socket.off('start-dialogue', handleStartDialogue);
      socket.off('continue-dialogue', handleContinueDialogue);
    };
  }, [socket, dispatch]);

  const selectDialogueOptions = dialogues?.map(getOption);
  const headerProps = {
    selectDialogueOptions,
    selectedDialogueId,
    handleSelectDialogue,
    handlSetNewDialogue,
  };
  const bodyProps = {
    dialogues,
    messagesEndRef,
    selectedDialogueId,
    scrollToBottom,
  };
  const footerProps = {
    loading,
    handleClick,
  };
  return (
    <Col className="documents">
      <Header {...headerProps} />
      <Body {...bodyProps} />
      <Footer {...footerProps} />
    </Col>
  );
};

export default Documents;
