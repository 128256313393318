import { WORK_TIME, POSLUGI_TIME, PSYCHOLOGIST_WORK_TIME } from 'common_constants/business';
import { warn } from '../../tools';

let warningShown = false;

export const checkBlokedTimes = (blockedArr, startT, endT) => {
  let isFreeTime = true;
  for (const key in blockedArr) {
    const i = blockedArr[key];
    if (WORK_TIME.indexOf(startT) < i && i < WORK_TIME.indexOf(endT)) {
      if (!warningShown) {
        warn('Години заброньовано!', 'Підберіть інший час для запису.', {
          startT: WORK_TIME.indexOf(startT),
          blockedArr: i,
          endT: WORK_TIME.indexOf(endT),
        });
        warningShown = true;
      }
      isFreeTime = false;
      break;
    }
  }

  if (isFreeTime) {
    warningShown = false;
  }

  return isFreeTime;
};

export const onTimeClick = (startT, endT, endT1, endT2, setEndT1, setEndT2, setStartT, eventTarget, type, psychologist, dayIndex) => {
  if (psychologist) {
    setStartT({ ...{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }, [dayIndex]: eventTarget.innerText });
    const index = PSYCHOLOGIST_WORK_TIME.indexOf(eventTarget.innerText);
    const dif = psychologist ? parseInt(type) : POSLUGI_TIME[type];

    const newI = PSYCHOLOGIST_WORK_TIME[index + dif - 1];

    setEndT1({ ...{ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }, [dayIndex]: newI });
    return;
  }
  if (startT && endT2) {
    setEndT1('');
    setEndT2('');
    setStartT(eventTarget.innerText);
  }
  if (!startT && !endT) {
    setStartT(eventTarget.innerText);
    const index = WORK_TIME.indexOf(eventTarget.innerText);
    const dif = POSLUGI_TIME[type];
    const newI = WORK_TIME[index + dif - 1];
    setEndT1(newI);
  }
  if (startT && (!endT1 || !endT2)) {
    if (!endT1) {
      const start = WORK_TIME.indexOf(startT);
      const end = WORK_TIME.indexOf(eventTarget.innerText);
      if (start >= end) {
        setStartT(eventTarget.innerText);
        setEndT1('');
      } else {
        setEndT1(eventTarget.innerText);
      }
    }
    if (endT1) setEndT2(eventTarget.innerText);
  }
};

export const onTimeClickForClient = (setEndT1, setStartT, eventTarget, type) => {
  const index = WORK_TIME.indexOf(eventTarget.innerText);
  const dif = POSLUGI_TIME[type];
  const newI = WORK_TIME[index + dif - 1];

  if (index + dif - 1 > 38) {
    warn('Спробуйте обрати раніший час!', 'Час завершення не повинен перевищувати 17:30');
    return;
  }
  setStartT(eventTarget.innerText);
  setEndT1(newI);
};

const getBookedTime = async (
  fil,
  type,
  date,
  setLoading,
  setBlockedArr,
  token,
  host,
  route,
  isUpdateClientHotline,
  hotlineStartT,
  hotlineEndT,
  isEditing,
) => {
  if (fil === undefined || !type || !date) return;
  setLoading(true);
  const response = await fetch(`${host}/hotline/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({ fil, type, date: date.toISOString() }),
  });
  const result = await response.json();
  const { booked, filiiStatus } = result;

  if (filiiStatus) {
    setBlockedArr(filiiStatus);
    setLoading(false);
    return;
  }

  if (isUpdateClientHotline || isEditing) {
    const hotlineStartTimeIndex = WORK_TIME.indexOf(hotlineStartT);
    const hotlineEndTimeIndex = WORK_TIME.indexOf(hotlineEndT);

    const hotlineBookedArr = [];
    for (let i = hotlineStartTimeIndex; i < hotlineEndTimeIndex; i++) {
      hotlineBookedArr.push(i);
    }

    const bookedWithoutHotlineTime = booked.filter((element) => !hotlineBookedArr.includes(element));
    setBlockedArr(bookedWithoutHotlineTime || []);
    setLoading(false);
    return;
  }

  setBlockedArr(booked || []);
  setLoading(false);
};

export const getBookedTimeForClient = (fil, type, date, setLoading, setBlockedArr, token, host) => {
  getBookedTime(fil, type, date, setLoading, setBlockedArr, token, host, 'getBookedTimeForClient');
};

export const getBookedTimeForCRM = (
  fil,
  type,
  date,
  setLoading,
  setBlockedArr,
  token,
  host,
  isUpdateClientHotline,
  hotlineStartT,
  hotlineEndT,
  isEditing,
) => {
  getBookedTime(
    fil,
    type,
    date,
    setLoading,
    setBlockedArr,
    token,
    host,
    'getBookedTimeForCRM',
    isUpdateClientHotline,
    hotlineStartT,
    hotlineEndT,
    isEditing,
  );
};

export const onTimeClear = (setStartT, setEndT1, setEndT2) => {
  setStartT('');
  setEndT1('');
  setEndT2('');
};
