import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUnreadedMessages, addNewMessageToChat, removeMessage, editMessage } from '../store/commonReducer';

const useListenMessages = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);
  const userAuth = useSelector((state) => state.common.userAuth);

  useEffect(() => {
    if (!socket) return;

    socket.on('newMessage', ({ message, type, chatId }) => {
      dispatch(addUnreadedMessages({ chatType: type, chatId }));
      dispatch(addNewMessageToChat({ chatType: type, chatId, data: { ...message, isSentByCurrentUser: userAuth._id === message.sender } }));
    });

    socket.on('removeMessage', ({ chatType, chatId, messageId }) => {
      dispatch(removeMessage({ chatType, chatId, messageId }));
    });

    socket.on('editMessage', ({ chatType, chatId, messageId, updatedMessage }) => {
      dispatch(editMessage({ chatType, chatId, messageId, updatedMessage }));
    });

    return () => {
      socket?.off('newMessage');
      socket?.off('removeMessage');
      socket?.off('editMessage');
    };
  }, [socket]);
};

export default useListenMessages;
