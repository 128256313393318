import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal, Form, Input, Button, InputNumber } from 'antd';
import { addPartner, updatePartner, setModal } from '../../store/commonReducer';
import { request, success, error } from '../../tools';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';
import CountryPhCode from '../../components/CountryPhCode';
import ScheduleItem from '../../components/ScheduleItem';

const defaultShedule = [
  { key: 1, startT: '08:00', endT: '17:00' },
  { key: 2, startT: '08:00', endT: '17:00' },
  { key: 3, startT: '08:00', endT: '17:00' },
  { key: 4, startT: '08:00', endT: '17:00' },
  { key: 5, startT: '08:00', endT: '17:00' },
  { key: 6, startT: null, endT: null },
  { key: 7, startT: null, endT: null },
];

const AddPartner = () => {
  const dispatch = useDispatch();
  const { editItem } = useSelector((state) => state.common.modal);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(editItem ? editItem?.schedule : defaultShedule);
  const [formData, setFormData] = useState({
    phone: editItem?.phone ?? '',
    countryPhCode: editItem?.CountryPhCode ?? COUNTRY_PHONE_CODES.UA, // Default to UA
  });

  useEffect(() => {
    if (editItem) {
      form.setFieldsValue({
        name: editItem.name,
        fil: editItem.fil,
        email: editItem.email,
        password: editItem.password,
        phone: editItem.phone,
        countryPhCode: editItem.countryPhCode,
        certificate: editItem.certificate,
        position: editItem.position,
        share: editItem.share,
        adress: editItem.adress,
        company: editItem.company,
      });
    }
  }, [editItem, form]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const requestURL = editItem ? `/partnersUsers/${editItem._id}` : '/partnersUsers/addPartnersUser';

    const partnersUserData = {
      name: form.getFieldValue('name'),
      fil: form.getFieldValue('fil'),
      email: form.getFieldValue('email'),
      password: form.getFieldValue('password'),
      countryPhCode: form.getFieldValue('countryPhCode'),
      phone: form.getFieldValue('phone'),
      certificate: form.getFieldValue('certificate'),
      position: form.getFieldValue('position'),
      share: form.getFieldValue('share'),
      adress: form.getFieldValue('adress'),
      company: form.getFieldValue('company'),
      schedule,
    };

    await request.post(
      requestURL,
      partnersUserData,
      ({ data }) => {
        const successMessage = editItem ? 'Партнер успішно відредагований.' : 'Партнер успішно доданий.';
        success('', successMessage);
        if (editItem) {
          dispatch(updatePartner(data));
        } else {
          dispatch(addPartner(data));
        }
        dispatch(setModal(false));
        setLoading(false);
      },
      () => {
        const errorMessage = editItem ? 'Помилка при редагуванні.' : 'Помилка при додаванні.';
        error('', errorMessage);
        setLoading(false);
      },
    );
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setFormData((prevData) => ({
      ...prevData,
      phone: input.slice(0, 10),
    }));
  };

  const handleCountryPhCodeChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      countryPhCode: value,
    }));
  };

  return (
    <Modal title={editItem ? 'Редагувати партнера' : 'Додати нового партнера'} open onCancel={() => dispatch(setModal(false))} footer={null}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleFormSubmit} initialValues={formData} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Form.Item label="ПІБ" name="name" rules={[{ required: true, message: 'Введіть ПІБ партнера' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Філія" name="fil" rules={[{ required: true, message: 'Введіть філію' }]}>
            <SearchSelectFil />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Введіть коректний email' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введіть пароль' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item name="phone" label="Телефон" rules={[{ required: true, message: 'Введіть телефон' }]}>
            <div style={{ display: 'flex' }}>
              <CountryPhCode value={formData.countryPhCode} onChange={handleCountryPhCodeChange} />
              <Input value={formData.phone} onChange={handlePhoneChange} maxLength={10} placeholder="Введіть номер телефону" />
            </div>
          </Form.Item>

          <Form.Item label="№ посвідчення" name="certificate" rules={[{ required: true, message: 'Введіть № посвідчення' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Адреса офісу" name="adress" rules={[{ required: true, message: 'Введіть адресу офісу' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Назва компанії" name="company">
            <Input />
          </Form.Item>
          <Form.Item label="Доля %" name="share" rules={[{ required: true, message: 'Введіть долю' }]}>
            <InputNumber min={0} max={100} />
          </Form.Item>
          <p>Графік роботи:</p>
          {[...schedule]
            .sort((a, b) => a.key - b.key)
            .map((item, index) => {
              return <ScheduleItem item={item} key={index} schedule={schedule} setSchedule={setSchedule} />;
            })}
          <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
            <Button type="primary" htmlType="submit">
              {editItem ? 'Редагувати' : 'Додати'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddPartner;
