import React, { useEffect, useRef, useState } from 'react';

import { Alert, Checkbox } from 'antd';
import { ROLES, WORK_TIME } from 'common_constants/business/index';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { makePravoRating } from '../PravoRating/PravoRating';
import { SelectState, CloseFilButton, FilInfo, OpenFilButton, SelectType, HotlineDatePicker, SelectFil } from 'common_components';
import { checkBlokedTimes, getBookedTimeForCRM, onTimeClear } from 'common_components/src/HotlineStep1/Step1/timeUtils';
import TimePicker from './Partials/TimePicker';
import RatingFreeAlert from './Partials/Alert/RatingFreeAlert';
import RatingAlert from './Partials/Alert/RatingAlert';
import DistantAlert from './Partials/Alert/DistantAlert';
import RatingMiddleAlert from './Partials/Alert/RatingMiddleAlert';
import SelectPravo from './Partials/SelectPravo';
import TipAlert from './Partials/Alert/TipAlert';
import WhichDayAlert from './Partials/Alert/WhichDayAlert';

dayjs.locale('uk');

const Step1 = ({
  data,
  setData,
  tip,
  lang = 'ua',
  filii,
  setLoading,
  token,
  host,
  activeFilii = [],
  onCloseFilClick,
  role,
  topFilials,
  updateClientHotline,
  onOpenFilClick,
  CloseCabinetModal,
  onCloseCabinetClick,
  getCurrentPravoRating,
  setIsFreeTime,
  buttonIsBlockedByTypeAndPravo,
  ...props
}) => {
  const pravoRating = props.pravoRating || [];
  const [currentItemRating, setCurrentItemRating] = useState('');
  const [filteredState, setFilteredState] = useState([]);
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  const [restArr, setRestArr] = useState([]);
  const [blockedArr, setBlockedArr] = useState([]);
  const [filteredPartOfDistrict, setFilteredPartOfDistrict] = useState([]);
  const [isOpenCabinetModal, setIsOpenCabinetModal] = useState(false);
  const timesInitted = useRef(false);

  const inspector = ROLES[role] <= ROLES.inspector;
  const deputyManagerHimOwnFill = ROLES[role] <= ROLES.deputyManager && props.fil === data.fil;

  const [startT, setStartT] = useState(data?.startT);
  const [endT, setEndT] = useState(data?.endT);

  const initialTimeValues = {
    startT: data?.startT,
    endT: data?.endT,
  };

  const remoteKonsultation = data.type === '3' || data.type === '8';

  const [lazy, setLazy] = useState(false);

  // const showNext =
  //   data.fil && data.type && data.pravo && data.date && startT && freeTime && (endT ? WORK_TIME.indexOf(startT) <= WORK_TIME.indexOf(endT) : true);

  const onChangeType = (value) => {
    setData({ ...data, type: String(value) });
  };

  const isDisabledByInfoCenter = pravoRating.find((i) => i.pravo === data.pravo)?.infoCenter ?? false;

  const currentTopFilialsIndexes = topFilials?.find((filial) => filial?.pravo === data.pravo)?.filials;

  const currentTopFilials = currentTopFilialsIndexes
    ?.map((filialIndex) => {
      const findedFilial = activeFilii.find((filial) => String(filial.value) === String(filialIndex));
      return findedFilial;
    })
    .filter((el) => !!el);

  const handleSortDataByState = (value) => {
    const selectedState = parseInt(value);
    const filteredByState = (filii || []).filter((filii) => {
      return (
        filii.states.includes(selectedState) &&
        filii.isDistrict !== true &&
        filii.isDisabled !== true &&
        !currentTopFilials.find((el) => el.value == filii.i)
      );
    });

    const filteredByDistrict = (filii || []).filter((filii) => {
      return (
        filii.states.includes(selectedState) &&
        filii.isDistrict === true &&
        filii.isDisabled !== true &&
        !currentTopFilials.find((el) => el.value == filii.i)
      );
    });

    const district = [...new Set(filteredByState.map((item) => item.district))];

    if (filteredByDistrict.length > 0) {
      filteredByDistrict.forEach((item) => {
        district.push(item.district);
      });
    }

    const filteredPartOfDistrict = (filii || []).filter((filii) => {
      return district.includes(filii.district) && filii.isDisabled !== true && !currentTopFilials.find((el) => el.value == filii.i);
    });

    const filteredPartOfDistrictWithoutFilteredByState = filteredPartOfDistrict.filter((filii) => {
      return (
        !filteredByState.some((item) => item._id === filii._id) &&
        !filteredByDistrict.some((item) => item._id === filii._id) &&
        !currentTopFilials.find((el) => el.value == filii.i)
      );
    });

    const restArray = (filii || []).filter((filii) => {
      return (
        !filteredByState.some((item) => item._id === filii._id) &&
        !filteredByDistrict.some((item) => item._id === filii._id) &&
        !filteredPartOfDistrictWithoutFilteredByState.some((item) => item._id === filii._id) &&
        filii.isDisabled !== true &&
        !currentTopFilials.some((el) => el.value == filii.i)
      );
    });

    setData((prev) => ({ ...prev, state: selectedState }));
    setFilteredState(filteredByState);
    setFilteredDistrict(filteredByDistrict);
    setFilteredPartOfDistrict(filteredPartOfDistrictWithoutFilteredByState);
    setRestArr(restArray);
  };

  const activeFilialsWithoutTops = activeFilii?.filter((activeFil) => {
    const res = currentTopFilials?.find((elem) => elem.value == activeFil.value);
    return !res;
  });

  const handleClickClearTime = () => onTimeClear(setStartT, setEndT, setEndT);

  const handleCompareTimeValues = (initialValues, changedValues) => {
    return initialValues.startT === changedValues.startT && initialValues.endT === changedValues.endT;
  };

  const onDateSelect = (value) => {
    setData({ ...data, date: value });
    handleClickClearTime();
  };

  useEffect(() => {
    setLazy(false);
    setCurrentItemRating(getCurrentPravoRating(data.pravo));
  }, [data.pravo]);

  useEffect(() => {
    setData({ ...data, startT, endT });
  }, [startT, endT]);

  useEffect(() => {
    if (data.type === '6') {
      setData({ ...data, fil: '3' });
    }
  }, [data.type]);

  useEffect(() => {
    setData({ ...data, lazy });
    if (lazy) setCurrentItemRating('Середня');
    else setCurrentItemRating(getCurrentPravoRating(data.pravo));
  }, [lazy]);

  useEffect(() => {
    if (timesInitted.current) {
      setStartT(undefined);
      setEndT(undefined);
    } else {
      timesInitted.current = true;
    }
  }, [data.fil]);

  const currentFil = filii.find((fil) => fil.i == data.fil);

  useEffect(() => {
    if (data.fil || data.type || data.date) {
      getBookedTimeForCRM(
        data.fil,
        data.type,
        data.date,
        setLoading,
        setBlockedArr,
        token,
        host,
        updateClientHotline,
        undefined,
        undefined,
        undefined,
        data?.isEditing,
      );
    }
  }, [data.fil, data.type, data.date]);

  useEffect(() => {
    setData({ ...data, startT, endT });
  }, [startT, endT]);

  useEffect(() => {
    const equalTime = handleCompareTimeValues(initialTimeValues, { startT: startT, endT: endT });

    equalTime ? setIsFreeTime(true) : setIsFreeTime(checkBlokedTimes(blockedArr, startT, endT));
  }, [blockedArr, startT, endT]);

  return (
    <div className="step1 hotline-step-1">
      <div className="step1-selects-inner">
        {/* Alerts */}
        <TipAlert tip={tip} />
        <RatingFreeAlert currentItemRating={currentItemRating} tip={tip} />
        <RatingMiddleAlert tip={tip} currentItemRating={currentItemRating} />
        <RatingAlert currentItemRating={currentItemRating} tip={tip} />
        <DistantAlert type={data.type} tip={tip} />

        <SelectType type={data.type ? String(data.type) : ''} onChangeType={onChangeType} isForCRM={true} lang={lang} />
        <SelectPravo data={data} setData={setData} pravoRating={pravoRating} />

        {data.type === '6' && isDisabledByInfoCenter && (
          <p style={{ border: '1px solid red', borderRadius: '5px', padding: '10px', color: 'red', display: 'inline-block', marginTop: '10px' }}>
            Заборонено записувати цей вид правовідносин як інформаційний центр
          </p>
        )}

        {buttonIsBlockedByTypeAndPravo && (
          <Alert description="Заборонено записувати цей вид правовідносин для цього типу консультації." type="warning" showIcon />
        )}

        <div style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}>
          <div style={{ marginBottom: 8, padding: 8, minWidth: 100 }}>
            За рейтингом по правовідносинам: <b style={{ display: 'inline-block', minWidth: 100 }}>{currentItemRating}</b>
          </div>
          <div>
            <Checkbox
              checked={lazy}
              onChange={(e) => {
                setLazy(e.target.checked);
                setData({ ...data, lazy: e.target.checked });
              }}
              disabled={currentItemRating !== 'Безкоштовна' && !lazy}
            >
              <span>Лінивець</span>
            </Checkbox>
          </div>
        </div>
        <div className="step1-select">
          <SelectState state={data.state ? String(data.state) : ''} handleSort={handleSortDataByState} lang={lang} />

          <SelectFil
            date={data.date}
            fil={data.fil ? String(data.fil) : ''}
            activeFilii={data.type === '0' ? activeFilialsWithoutTops : activeFilii}
            setLoading={setLoading}
            setBlockedArr={setBlockedArr}
            token={token}
            host={host}
            type={data.type}
            onChangeFil={(v) => setData((prev) => ({ ...prev, fil: v }))}
            isForCRM={true}
            filteredState={filteredState}
            filteredDistrict={filteredDistrict}
            filteredPartOfDistrict={filteredPartOfDistrict}
            restArr={restArr}
            filii={filii}
            state={data.state}
            currentTopFilials={currentTopFilials}
            lang={lang}
          />
        </div>
      </div>
      {remoteKonsultation && (
        <div style={{ textAlign: 'right' }}>
          <Checkbox
            className="vip"
            style={{ width: '50%' }}
            checked={data.vip}
            onChange={(e) => {
              setData({ ...data, vip: e.target.checked });
            }}
          >
            <span>VIP консультація</span>
          </Checkbox>
        </div>
      )}
      {!!data.fil && <FilInfo fil={data.fil} filii={filii} lang={lang} />}
      {!!currentFil?.note && (
        <Alert
          message="Нотатка до філії"
          style={{ marginBottom: 10, borderRadius: 32 }}
          description={<span>{currentFil.note}</span>}
          type="warning"
        />
      )}

      <WhichDayAlert fil={data.fil} tip={tip} type={data.type} />

      {!!data.fil && !!data.type ? (
        <HotlineDatePicker onDateSelect={onDateSelect} fil={data.fil} isForCRM={false} date={data?.date ?? dayjs(new Date())} />
      ) : null}

      <TimePicker
        handleClickClearTime={handleClickClearTime}
        blockedArr={blockedArr}
        data={data}
        endT={endT}
        lang={lang}
        setEndT={setEndT}
        setStartT={setStartT}
        startT={startT}
      />
      {data.fil && (inspector || deputyManagerHimOwnFill) && (
        <div className="opened_closed_responsive">
          <CloseFilButton closeFil={() => onCloseFilClick(data.fil, data.date)} />
          &nbsp;
          <OpenFilButton openFil={() => onOpenFilClick(data.fil, data.date)} />
          <CloseCabinetModal
            fil={data.fil}
            date={data.date}
            isOpenCabinetModal={isOpenCabinetModal}
            onCloseCabinetClick={onCloseCabinetClick}
            setIsOpenCabinetModal={setIsOpenCabinetModal}
          />
        </div>
      )}
    </div>
  );
};

export default Step1;
