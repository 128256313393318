import React from 'react';
import { useSelector } from 'react-redux';
import { PAY_PURPOSES, PAY_TYPES, CURRENCIES } from 'common_constants/business';
import { Card } from 'antd';
import dayjs from 'dayjs';

const DublicateInfo = ({ data }) => {
  const FILII = useSelector((state) => state.common.FILII);

  return (
    <Card
      title={`№${data.i} - ${dayjs(data.createdAt).format('DD.MM.YYYY')}`}
      size="small"
      className="dublicate-info"
      style={{ borderColor: '#' + data?.color }}
    >
      <p>
        <b>Філія:</b> {FILII[data.fil]}
      </p>

      <p>
        <b>№ сахорку:</b> {data.i}
      </p>

      {data.name && (
        <p>
          <b>ПІБ:</b> {data.name}
        </p>
      )}

      {data.contractIndex && (
        <p>
          <b>№ договору:</b> {data.contractIndex}
        </p>
      )}

      <p>
        <b>Призначення платежу:</b> {PAY_PURPOSES?.[data?.payfor]?.label}
      </p>

      <p>
        <b>Спосіб оплати:</b> {PAY_TYPES[data.payBy]?.label} {data.payByOther}
      </p>

      <p>
        <b>Сума:</b> {data.currency ? CURRENCIES[data.currency].symbol || '₴' : '₴'}
        {data.amount}
      </p>
    </Card>
  );
};

export default DublicateInfo;
