import React, { useCallback, useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';
import MessageRow from './MessageRow';

const MessagesList = ({
  width,
  height,
  messages,
  listRef,
  handleOnContextMenu = () => {},
  rowCount,
  highlightedMessageIndex = null,
  handleDownloadFile,
  handleListScroll = () => {},
  cachedImages,
}) => {
  const sizeMap = useRef({});

  const setSize = (index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size ?? 100 };
    listRef.current.resetAfterIndex(index);
  };

  const getSize = useCallback((index) => sizeMap.current[index] ?? 100, [sizeMap.current]);

  useEffect(() => {
    if (!listRef.current || !messages || messages?.length === 0) return;

    listRef.current.scrollToItem(messages?.length + 1);
  }, [messages, listRef.current]);

  if (!messages?.length) return <></>;

  return (
    <List ref={listRef} height={height} width={width} itemCount={rowCount ?? 0} itemSize={getSize} onScroll={handleListScroll}>
      {({ index, style }) => {
        return (
          <div className={clsx('message-wrapper', index === highlightedMessageIndex ? 'highlight' : '')} style={style}>
            <MessageRow
              data={messages}
              index={index}
              setSize={setSize}
              handleOnContextMenu={handleOnContextMenu}
              hasNext={false}
              handleDownloadFile={handleDownloadFile}
              cachedImages={cachedImages}
            />
          </div>
        );
      }}
    </List>
  );
};

export default MessagesList;
