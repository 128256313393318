import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, InputNumber, Button } from 'antd';

import { error, request } from '../../tools';
import { editContract, setModal } from '../../store/commonReducer';

import './ContractCreateCall.scss';

const ContractCreateCall = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.common.modal.data);

  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(setModal());
  };

  const handleCreateCall = async () => {
    setIsLoading(true);

    await request.post(
      '/contracts/addCall',
      {
        contractId: modalData.contractId,
        seconds: totalSeconds,
      },
      ({ calls }) => {
        dispatch(editContract({ _id: modalData.contractId, calls: calls }));
        dispatch(setModal());
      },
      error,
    );

    setIsLoading(false);
  };

  return (
    <Modal open title="Створення дзвінка по договору" width={400} footer={null} onCancel={handleCloseModal} className="contract-create-call">
      <div className="wrapper">
        <InputNumber
          className="input-number"
          value={totalSeconds}
          onChange={(num) => setTotalSeconds(num)}
          min={0}
          placeholder="Введіть кількість секунд..."
        />
        <Button type="primary" onClick={handleCreateCall} loading={isLoading} disabled={isLoading}>
          Надіслати
        </Button>
      </div>
    </Modal>
  );
};

export default ContractCreateCall;
