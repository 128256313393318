import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dialogues: [],
  typingEffect: false,
  placeholder: {
    status: false,
    message: "",
  },
  thinking: false,
};

export const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    setDialogues: (state, action) => {
      state.dialogues = action.payload;
    },
    addDialogue: (state, action) => {
      state.dialogues.push(action.payload);
    },
    addMessage: (state, action) => {
      const { dialogueId, message } = action.payload;
      const dialogueIndex = state.dialogues.findIndex((dialogue) => dialogue._id === dialogueId);
      state.dialogues[dialogueIndex].dialogue.push(message);
    },
    setTypingEffect: (state, action) => {
      state.typingEffect = action.payload;
    },
    setPlaceholder: (state, action) => {
      state.placeholder = action.payload;
    },
    setThinking: (state, action) => {
      const { dialogueId, thinking } = action.payload;
      const index = state.dialogues.findIndex((dialogue) => dialogue._id === dialogueId);
      if (index !== -1) {
        state.dialogues[index].thinking = thinking;
      }
    },
  },
});

export const selectDialogues = (state) => state.ai.dialogues;
export const selectTypingEffect = (state) => state.ai.typingEffect;
export const selectPlaceholder = (state) => state.ai.placeholder;
export const selectThinking = (state) => state.ai.thinking;
export const {
  setDialogues,
  addMessage,
  addDialogue,
  setTypingEffect,
  setPlaceholder,
  setThinking,
} = aiSlice.actions;

export default aiSlice.reducer;
