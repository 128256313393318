import React, { useState, useEffect } from 'react';
import { Image } from 'antd';

import ChatSpinner from '../ChatSpinner/ChatSpinner';
import Column from '../../Column/Column';

import './ChatImage.scss';

const ChatImage = ({ item, cachedImages }) => {
  const token = window.localStorage.getItem('accessToken');
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(cachedImages.current[item.fileId] || '');

  const fetchImage = async () => {
    try {
      setImageLoading(true);
      const response = await fetch(process.env.REACT_APP_API + '/chat/getChatDriveImage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileId: item.fileId }),
      });
      if (!response.ok) throw new Error('Failed to fetch image');
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      cachedImages.current[item.fileId] = url; // Cache the URL
      setImageUrl(url);
      setImageLoading(false);
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  useEffect(() => {
    if (cachedImages.current[item.fileId]) return;

    fetchImage();
  }, [item.fileId, cachedImages]);

  return (
    <Column style={{ height: '96%' }}>
      {imageLoading ? (
        <ChatSpinner />
      ) : (
        <div className="chatImage-wrapper">
          <Image className="chatImage-ant" src={imageUrl} alt={item.fileName || 'Image'} />
        </div>
      )}
    </Column>
  );
};

export default ChatImage;
